/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for re-balance;
 *   Integrations :  12-08-2022
 *   Version : v1.0
 *   Created :  08-12-2023
*/
/*import packages*/
import { useState, useEffect, useRef, Fragment } from 'react';
import $ from 'jquery';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import SelectBox from '../../components/dropdown/selectBox';
import { PrimaryBtn } from '../../components/cta';
import { Loader } from '../../components/loader';
import { FilterBtn } from '../../components/cta/index';
import { EmptyNote, Toast } from '../../components/note';
import FundsTab from '../../components/dataFormating/fundsTab';
import { FileUpload, Input } from '../../components/input/index';
// import Preview from '../../assets/images/banner/preview.svg';
import LoaderImg from '../../assets/images/loader/mintd-loader.gif'
import Delete from '../../assets/images/modal/delete.svg';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import { IconStyleToolTip } from '../../components/badge';
import { TriggerModal } from '../../components/modal';

/* ---- investment types for goals ----*/
const TABS_OPTIONS = [
    {
        id: 1,
        displayName: "Rebalance records"
    },
    {
        id: 2,
        displayName: "Upload CSV"
    },

]


const Index = (props) => {
    const searchRef = useRef(null);
    const wrapperRef = useRef(null);

    const [index, setIndex] = useState(1);
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(null);
    const [rebalanceData, setRebalanceData] = useState([]);
    const [filterDown, setFilterDown] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [success, setSuccess] = useState(null);
    const [versionOption, setVersionOption] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [imageName, setImageName] = useState("");
    const [fileUpload, setFileUpload] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const [search, setSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [triggerLoader, setTriggerLoader] = useState(false);
    const [userUrl, setUserUrl] = useState("");
    const [csvName, setCsvName] = useState("");
    const [csvLoader, setCsvLoader] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [goalId, setGoalId] = useState(null);
    const [goalFilter, setGoalFilter] = useState([]);
    const [tooltip, setTooltip] = useState(false);
    const [fileError, setFileError] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        _getVersionList();
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mouseup', _closeTooltip);
        return () => {
            document.removeEventListener('mouseup', _closeTooltip);
        };
    }, []);


    useEffect(() => {
        _getRebalanceList();
    }, [selectedCustomer, goalId, selectedStatus]);


    useEffect(() => {
        _goalFilterData()
    }, [selectedCustomer])


    /*---- order table columns -----*/

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.rebalance_id ? row.rebalance_id : '--',
            sortable: true,
            left: true,
            minWidth: "100px",
            maxWidth: "100px",
            defaultSortAsc: false,
        },
        {
            name: 'Cust#',
            selector: (row) => row.customer_id ? row.customer_id : '--',
            sortable: true,
            left: true,
            minWidth: "110px",
            maxWidth: "110px",
            defaultSortAsc: false,
        },
        {
            name: 'MIRA Cust #',
            selector: (row) => row.mira_customer_id ? row.mira_customer_id : '--',
            sortable: true,
            left: true,
            minWidth: "160px",
            maxWidth: "160px",
            defaultSortAsc: false,
        },
        {
            name: 'Customer Name',
            selector: (row) => row.customer_name ? row.customer_name : '--',
            sortable: true,
            left: true,
            minWidth: "150px",
            maxWidth: "200px",
            defaultSortAsc: false,
        },
        {
            name: 'Goal Name',
            selector: row => row.goal_name,
            sortable: true,
            left: true,
            minWidth: '150px',
            maxWidth: "300px",
            defaultSortAsc: false,
        },
        {
            name: 'Txn Type',
            selector: row => row.transaction_type,
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '130px',
            defaultSortAsc: false,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '150px',
            defaultSortAsc: false,
        },
        {
            name: 'P/R Fund Name',
            selector: row => row.mf_name,
            sortable: true,
            left: true,
            minWidth: '300px',
            minWidth: '400px',
            defaultSortAsc: false,
        },
        {
            name: 'Switch Out Fund Name',
            selector: row => row.switch_out_mf_name,
            sortable: true,
            left: true,
            minWidth: '300px',
            maxWidth: '400px',
            defaultSortAsc: false,
        },
        {
            name: 'Switch In Fund Name',
            selector: row => row.switch_in_mf_name,
            sortable: true,
            left: true,
            minWidth: '300px',
            maxWidth: '400px',
            defaultSortAsc: false,
        },
        {
            name: 'Current Alloc.',
            selector: row => row.current_allocation.toFixed(2),
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '100px',
            defaultSortAsc: false,
        },
        {
            name: 'New Alloc.',
            selector: row => row.new_allocation.toFixed(2),
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '100px',
            defaultSortAsc: false,
        },
        {
            name: 'Diff',
            selector: row => row.switch.toFixed(2),
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '100px',
            defaultSortAsc: false,
        },
        {
            name: 'Delta',
            selector: row => row.delta.toFixed(2),
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '100px',
            defaultSortAsc: false,
        },
        {
            name: 'Amount',
            selector: row => row.amount.toFixed(2),
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '100px',
            defaultSortAsc: false,
        }

    ]


    const _closeMenu = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    }


    const _closeTooltip = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setTooltip(false)
        }
    }

    /*--------- order list -----------*/
    const _getRebalanceList = () => {

        let url = devconfig.admin + '/rebalance/rebalance-history-detail';
        let data = JSON.stringify({
            "goal_id": goalId !== null ? goalId.value : goalId,
            "customer_id": selectedCustomer !== null ? selectedCustomer.id : null,
            "version_id": selectedStatus !== null ? selectedStatus.value : null
        })
        APIPost(url, data).then((response) => {

            if (response.status_code === 200) {
                setRebalanceData(response.data);
            } else {
                setRebalanceData([]);
                // setError(response.message);
            }
            setLoader(false);
        });
    }

    /*--------- version list -----------*/
    const _getVersionList = () => {

        let url = devconfig.admin + '/rebalance/version-history';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                console.log("version data", response.data);
                var version_options = [];
                version_options.push({
                    label: "All",
                    value: null
                })
                response.data.map(function (item, index) {
                    var item = {
                        value: item.version_id,
                        label: item.version
                    };
                    version_options.push(item);
                });
                setVersionOption(version_options);
            }

        })
    }


    const _goalFilterData = () => {
        let url = devconfig.admin + '/rebalance/goal-filter-data';
        let data = JSON.stringify({
            "customer_id": selectedCustomer !== null ? selectedCustomer.id : null,
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                var goal_options = [];
                goal_options.push({
                    label: "All",
                    value: null
                })
                response.data.map(function (item, index) {
                    var item = {
                        value: item.id,
                        label: item.label
                    };
                    goal_options.push(item);
                });
                setGoalFilter(goal_options);
            }

        });
    }





    //upload banner
    const _uploadFile = (temp, for_users) => {
        setFileError("");
        if (for_users) {
            setCsvLoader(true)
        } else {
            setFileUpload(true)
        }
        let url = devconfig.admin + '/file/upload'

        let data = JSON.stringify({
            file: temp.name,
            is_public: true,
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (for_users) {
                    setCsvLoader(true);
                } else {
                    setFileUpload(true)
                }
                _uploadDocument(response.data, temp, for_users); // upload file in AWS s3
            } else {
                setError(response.message);
                if (for_users) {
                    setCsvLoader(false)
                } else {
                    setFileUpload(false)
                }
            }
        })
    }

    /* Uploading to s3 */
    const _uploadDocument = async (data, file, for_users) => {
        if (for_users) {
            setCsvLoader(true)
        } else {
            setFileUpload(true)
        }
        var formdata = new FormData();
        formdata.append("key", data.upload_info.fields.key);
        formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
        formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
        formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
        formdata.append("policy", data.upload_info.fields['policy']);
        formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
        formdata.append("file", file, file.name);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(data.upload_info.url, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (for_users) {
                    setCsvLoader(false);
                    setUserUrl(data.view_info);
                    setCsvName(file.name);
                } else {
                    setFileUpload(false)
                    setImageUrl(data.view_info);
                    setOpenPopup(true);
                }
            })
            .catch(error => {
                // console.log('error', error); // show error toast here
            });
    }

    //upload csv
    const _triggerUpload = () => {
        let valid = true;
        if (imageUrl === "") {
            setFileError("Please upload rebalance CSV");
            valid = false
        }
        if (valid === true) {
            setTriggerLoader(true);
            let api_url = devconfig.admin + '/rebalance/upload-csv'
            let data = JSON.stringify({
                url: imageUrl,
            });
            APIPost(api_url, data).then((response) => {
                if (response.status_code === 200) {
                    setSuccess("CSV uploaded successfully");
                    setTriggerLoader(false);
                    window.location.reload();
                } else {
                    setError(response.message);
                    setTriggerLoader(false);
                }
            });

        }

    }

    const _getCustomerFilterData = (query = null) => {
        setSelectedCustomer(null);
        setGoalId(null);
        let api_url = devconfig.admin + '/rebalance/customer-filter-data';
        let data = JSON.stringify({
            query: query
        });
        APIPost(api_url, data).then((response) => {
            if (response.status_code === 200) {
                setShowDropdown(true);
                setCustomerList(response.data);
            } else {
                setError(response.message);
                setLoader(false);
            }
        });
    }



    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-2">
                            <div className='col-lg-12'>
                                <FundsTab
                                    data={TABS_OPTIONS}
                                    index={index}
                                    funds={true}
                                    setTab={(temp, name) => {
                                        setIndex((temp));

                                    }} />
                            </div>
                            {
                                index === 1 ?
                                    <div className='row mt-3'>
                                        <div className="col-lg-8 col-10">
                                            <h6 className="e-section-title p-2">All re-balance</h6>
                                        </div>
                                        <div className='col-lg-3 text-end position-relative'>
                                            <PrimaryBtn
                                                name="Trigger Rebalance"

                                                toggle="modal"
                                                target="#trigger"
                                                handleClick={() => {
                                                    // _triggerRebalance()
                                                }}
                                            />
                                            <img
                                                className="ps-2"
                                                src={require("../../assets/images/dataFormating/Icon.svg").default} ref={wrapperRef} alt=""
                                                onMouseEnter={() => setTooltip(true)}
                                                onMouseLeave={() => setTooltip(false)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>

                                        <div className="col-lg-1 col-1 ">
                                            <FilterBtn
                                                className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
                                                handleClick={() => {
                                                    setFilterDown(!filterDown);
                                                }}
                                            />
                                        </div>
                                        {
                                            filterDown ?
                                                <div className="e-filter-wraper e-sort mt-3">
                                                    <div className="row">

                                                        <div className="col-lg-3 col-4 px-2">
                                                            <label className="mb-2">Version</label>
                                                            {versionOption && versionOption.length > 0 && (
                                                                <SelectBox
                                                                    value={selectedStatus}
                                                                    placeholder="Select Version"

                                                                    options={versionOption}
                                                                    selectchange={(value) => {
                                                                        setSelectedStatus(value);

                                                                    }}
                                                                    isSearchable={false}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className='col-lg-3 position-relative' >
                                                            <label className="mb-2">Customer</label>
                                                            <div onClick={() => _getCustomerFilterData(null)}>
                                                                <Input type='text'
                                                                    placeholder="Search for customer"
                                                                    className='py-2 e-customer-input'
                                                                    value={selectedCustomer !== null ? selectedCustomer.label : search}
                                                                    valueChange={(value) => {
                                                                        setSearch(value);
                                                                        _getCustomerFilterData(value)
                                                                    }} />
                                                            </div>
                                                            {
                                                                showDropdown ?
                                                                    <div className='position-absolute e-show-search-dropdown' ref={searchRef}>
                                                                        {customerList.map((item, index) => {
                                                                            return (
                                                                                <p key={index} onClick={() => {
                                                                                    setSelectedCustomer(item);
                                                                                    setShowDropdown(false);
                                                                                }}>{item.label}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                        {
                                                            selectedCustomer !== null ?
                                                                <div className="col-lg-3 col-4 px-2">
                                                                    <label className="mb-2">Goal</label>
                                                                    {goalFilter && goalFilter.length > 0 && (
                                                                        <SelectBox
                                                                            value={goalId}
                                                                            placeholder="Select Goal"

                                                                            options={goalFilter}
                                                                            selectchange={(value) => {
                                                                                setGoalId(value);

                                                                            }}
                                                                            isSearchable={false}
                                                                        />
                                                                    )}
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        {/*--------------- Order table -----------*/}
                                        {
                                            loader ?
                                                <Loader />
                                                : (!rebalanceData.length) ?
                                                    <EmptyNote note="There are no rebalance data to show" />
                                                    :
                                                    <div className="col-lg-12 px-0">
                                                        <DataTable
                                                            pagination={true}
                                                            columns={columns}
                                                            data={rebalanceData}
                                                            perPage={30}
                                                            selectableRows={false}
                                                        />
                                                    </div>
                                        }
                                    </div>

                                    :
                                    <>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6 pe-0'>
                                                <label>Upload rebalance CSV</label>
                                                {
                                                    previewUrl ?
                                                        <div className='e-preview position-relative mb-3'>

                                                            {
                                                                fileUpload ?
                                                                    <img src={LoaderImg} alt="" height="80px" width="100%" style={{ objectFit: "contain", }} />
                                                                    :
                                                                    <div className='d-flex'>
                                                                        <img src={Delete} alt="delete"
                                                                            width={"32px"} className='position-absolute e-delete-image' style={{ right: "16px", cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                setPreviewUrl("");
                                                                                setImageUrl("");
                                                                                setImageName("");
                                                                            }} />

                                                                        <span>{imageName}</span>
                                                                    </div>
                                                            }
                                                            <span className='e-choose-btn' onClick={() => { $('#e-upload').trigger("click") }}>
                                                                <b><u>Change csv</u></b>
                                                            </span>
                                                            {/* <span className='e-drag-text'>(Drag and drop image or click here to select image)</span> */}
                                                            <FileUpload
                                                                accept={"*"}
                                                                className="d-none"
                                                                id="e-upload"
                                                                key="e-upload-image"
                                                                upload={(temp) => {
                                                                    // setUploadImage(temp.name)
                                                                    let preview = URL.createObjectURL(temp);
                                                                    setPreviewUrl(preview)
                                                                    _uploadFile(temp)
                                                                }} />
                                                            {
                                                                fileError &&
                                                                <p className={"e-error"}>{fileError}</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Fragment>
                                                            <div className="e-uploader " onClick={() => { $('#e-upload').click() }}>
                                                                <div className='text-center'>

                                                                    <p className='text-center mb-2'>
                                                                        {
                                                                            imageName ? imageName : "Upload csv"
                                                                        }
                                                                    </p>
                                                                    {/* 
                                                            video !== 1 ?
                                                            <span className='e-drag-text'>(Drag and drop image or click here to select image)</span>
                                                            :
                                                            null */}
                                                                </div>
                                                                <FileUpload
                                                                    accept={".csv"}
                                                                    className="d-none"
                                                                    id="e-upload"
                                                                    key="e-upload-image"
                                                                    upload={(temp) => {
                                                                        setImageName(temp.name);
                                                                        let preview = URL.createObjectURL(temp);
                                                                        setPreviewUrl(preview);
                                                                        _uploadFile(temp)
                                                                    }} />

                                                            </div>
                                                            {
                                                                fileError &&
                                                                <p className={"e-error position-absolute"}>{fileError}</p>
                                                            }
                                                        </Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-lg-4'>
                                                <PrimaryBtn
                                                    name="Submit"
                                                    className={triggerLoader ? "e-btn-loader " : ""}
                                                    handleClick={() => {
                                                        _triggerUpload()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
            {
                tooltip ?

                    <IconStyleToolTip
                        className="e-icon-tooltip"
                        more={true}
                        content="An email will be sent to hello@mira.money with rebalance details." />

                    :
                    null
            }
            <TriggerModal />


        </>
    );
}

export default Index;

