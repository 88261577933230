/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Orders container;
 *   Integrations :  12-08-2022
 *   Version : v1.0
 *   Created :  12-09-2022
*/
/*import packages*/
import { useState, useEffect, useRef, Fragment } from 'react';
import devconfig from '../../config/config';
import { EmptyNote } from '../../components/note';
import { DataTable } from '../../components/dataFormating';
import { Loader } from '../../components/loader';
import APIPost from '../../services/postApi';
import Tooltip from '../../components/badge/tooltip';
import { useNavigate } from 'react-router-dom';
import SelectBox from '../../components/dropdown/selectBox';
import APIGet from '../../services/getApi';


const PendingOrders = () => {
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const [orderStatusOptions, setOrderStatusOptions] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);

    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [loader, setLoader] = useState(true);

    const [totalRecords, setTotalRecords] = useState(null);

    useEffect(() => {
        _filterData();
    }, []);

    useEffect(() => {
        if (selectedOrderStatus !== null && selectedCustomer !== null) {
            getOrderList();
        }
        //eslint-disable-next-line
    }, [selectedOrderStatus, perPage, pageNum, selectedCustomer]);

    const columns = [
        {
            name: 'MIRA ID',
            selector: (row) => row.mira_customer_id ? row.mira_customer_id : '--',
            sortable: true,
            left: true,
            width: "150px",
            defaultSortAsc: false,
        },
        {
            name: 'Created on',
            selector: row => row.created,
            sortable: false,
            left: true,
            minWidth: '200px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_time',),
            cell: row => <div className="col-lg-12" >
                {row.created}
            </div>
        },
        {
            name: 'Name',
            selector: row => row.customer_name,
            sortable: true,
            left: true,
            minWidth: '160px',
            cell: row => <>
                {
                    row.customer_name ?
                        <div className='e-link' onClick={() => {
                            navigate(`/user-detail?id=${row.customer_id}`, {
                                state: {
                                    tab: 2
                                }
                            })
                        }}>{row.customer_name}</div>
                        :
                        "--"
                }
            </>
        },
        {
            name: 'Amount',
            selector: row => row.amount ? parseFloat(row.amount) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                ₹{
                    row.amount ?
                        row.amount.toFixed(2)
                        :
                        0
                }
            </div>

        },
        {
            name: 'ISIN',
            selector: row => row.isin,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                {row.isin}
            </div>

        },
        {
            name: 'Units',
            selector: row => row.units ? row.units : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                {row.units ? row.units : "-"}
            </div>

        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            left: true,
            minWidth: "170px",

            // cell: row => <div className="">
            //   <Badge type="success" text="success"/>
            // </div>

            cell: (row, key) => <>{row.status ?
                row.status
                :
                "--"}</>
        },
        {
            name: 'Goal name',
            selector: row => row.goal_name,
            sortable: true,
            left: true,
            minWidth: '180px',
            cell: (row) => <div className="col-lg-12">
                {row.goal_name}
            </div>
        },
        {
            name: 'Type',
            selector: row => row.order_type,
            sortable: true,
            left: true,
            width: "180px",
            cell: (row) => <div className="col-lg-12">
                {row.order_type}
            </div>
        },
    ]

    const _handlePageChange = (page_num) => {
        setPageNum(page_num);
    };

    const _handlePerPage = (per_page) => {
        setPerPage(per_page);
    };

    /*--------- order list -----------*/
    const getOrderList = (query) => {
        let url = devconfig.admin + '/order/reorder-list';

        let data = JSON.stringify({
            "customer_id": selectedCustomer.value,
            "status": selectedOrderStatus.value,
            page_num: pageNum,
            page_size: perPage,

        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data.data);
                setTotalRecords(response.data.total_records)
            } else {
                setOrderData([]);
            }
            setLoader(false);
        });
    }

    /*---- filter data api --*/
    const _filterData = () => {
        let status_options_temp = [];

        status_options_temp.push({
            label: "All",
            value: null
        })


        let customers_options_temp = [];

        customers_options_temp.push({
            label: "All",
            value: null
        })

        let url = devconfig.admin + '/order/re-order-filter';

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                response.data.status.map((item) => {
                    status_options_temp.push({ value: item.label, label: item.label });
                    return true;
                });
                setOrderStatusOptions(status_options_temp);
                setSelectedOrderStatus(status_options_temp[0]);

                response.data.customer_ids.map((item) => {
                    customers_options_temp.push({ value: item.id, label: item.name });
                    return true;
                });
                setCustomerOptions(customers_options_temp);
                setSelectedCustomer(customers_options_temp[0]);
            }
        });
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0 py-1">
                        {
                            loader ?
                                <Loader />
                                :
                                <div className="row pt-2">
                                    <div className="col-lg-12">
                                        <h6 className="e-section-title mb-0">All Orders
                                            {/* <TextBtn name="View Summary"
										className="ms-2"
										handleClick={() => _handleShowSummary(true)} /> */}
                                        </h6>
                                    </div>
                                    <div className="e-filter-wraper e-sort mt-1">
                                        <div className="row align-items-end">
                                            <div className="col-lg-2 pe-1 col-3 ">
                                                <label className="mb-1">Order status</label>
                                                <SelectBox options={orderStatusOptions}
                                                    value={selectedOrderStatus}
                                                    placeHolder="Select order status"
                                                    isSearchable={false}
                                                    selectchange={(value) => {
                                                        setSelectedOrderStatus(value);
                                                    }}
                                                />
                                            </div>

                                            <div className="col-lg-2 px-1 col-3 ">
                                                <label className="mb-1">Customer</label>
                                                <SelectBox options={customerOptions}
                                                    value={selectedCustomer}
                                                    placeHolder="Select customer"
                                                    isSearchable={false}
                                                    selectchange={(value) => {
                                                        setSelectedCustomer(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {/*--------------- Order table -----------*/}
                        {
                            (!orderData.length) ?
                                <EmptyNote note="There are no orders to show" />
                                :
                                <div className="col-lg-12 px-0">
                                    <DataTable
                                        pagination={true}
                                        columns={columns}
                                        data={orderData}
                                        perPage={perPage}
                                        paginationServer={true}
                                        selectableRows={false}
                                        paginationTotalRows={totalRecords}
                                        onChangeRowsPerPage={_handlePerPage}
                                        onChangePage={_handlePageChange}
                                        selectRows={() => { }}
                                        rowClick={(row) => navigate(`/user-detail?id=${row.customer_id}`, {
                                            state: {
                                                tab: 2
                                            }
                                        })} />
                                </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PendingOrders