/*
 *   File : transactions.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Holdingscontainer;
 *   Integrations :14-11-2022
 *   Version : v1.0
 *   Created : 10-12-2021
*/
/*import packages*/
import React, { useState, useEffect, useRef } from 'react';

/*import components*/
import { LineChartMarker } from '../../components/chart';
// import { IconStyleToolTip } from '../../components/badge';

/*import assets*/
// import Icon1 from '../../assets/images/dataFormating/icon1.svg';
// import Icon2 from '../../assets/images/dataFormating/icon2.svg';
// import Icon from '../../assets/images/dataFormating/Icon.svg'

/* import services */
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import { Loader } from '../../components/loader';
import { EmptyNote } from '../../components/note';
import { IconStyleToolTip } from '../../components/badge';

/*import services*/


const Holdings = (props) => {
    const wrapperRef = useRef(null);
    const [tooltip, setTooltip] = useState(false);
    const [loader, setLoader] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [labels, setLabels] = useState([]);
    const [targetData, setTargetData] = useState([]);
    const [performanceData, setPerformanceDate] = useState([]);
    const [images, setImages] = useState([])
    const [goalData, setGoalData] = useState([]);

    useEffect(() => {
        getHoldingsData() //api call
        //eslint-disable-next-line
    }, [])

    /* ----- closing tooltip on mouse click in any other position of screen ----*/
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);

    const closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setTooltip(false)
        }
    }

    /*------ get holdings details -----------*/
    const getHoldingsData = () => {
        setLoader(true)
        let url = devconfig.admin + '/user/holdings';

        let data = JSON.stringify({
            user_id: Number(props.id)
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                setSummaryData(response.data.summary);
                let tempLabels = []
                let tempData = []
                let tempImages = []
                let tempTarget = []

                tempLabels.push(0)
                tempData.push(0)
                tempImages.push({
                    src: ""
                })
                // tempTarget.push(0)
                // for (let i = 0; i < response.data.investment_growth.length; i++) {
                //     tempTarget.push(0)
                // }

                // tempTarget[1] = response.data.expected_growth[0].y
                // tempTarget[tempTarget.length - 1] = response.data.expected_growth[1].y

                response.data.goals && response.data.goals.map((item) => {
                    return (
                        tempLabels.push(item.target_year)
                    )
                })

                response.data.goals && response.data.goals.map((item) => {
                    return (
                        tempData.push(item.target)
                    )
                })

                response.data.goals && response.data.goals.map((item) => {
                    return (
                        tempImages.push({ src: item.icon })
                    )
                })
                response.data.expected_growth && response.data.expected_growth.map((item) => {
                    return (
                        tempTarget.push(item.y)
                    )
                })

                // for (let i = response.data.goals.length; i > 0; i--) {
                // tempTarget.shift(response.data.goals[response.data.goals.length - 1].target)
                // }
                const maximum = Math.max(...tempData)

                for (let i = 1; i < response.data.goals.length; i++) {
                    tempTarget.push(maximum)
                }

                setLabels(tempLabels)
                setPerformanceDate(tempData)
                setImages(tempImages)
                setTargetData(tempTarget)
                setGoalData(response.data.goals)
            } else {
                setSummaryData({});
                setLabels([]);
                setLoader(false)
                setPerformanceDate([])
                setTargetData([]);
                setGoalData([]);
            }
        })
    }

    return (
        <>
            {

                loader ?
                    <div className="card e-dashboard-card border-0 p-4 pt-5 e-profile-holdings">
                        <Loader />
                    </div>
                    :
                    <>
                        <div className="row">
                            <div className="col-lg-4 col-12">
                                <div className="card e-dashboard-card border-0 e-profile-holdings">
                                    <h4 className=''>Portfolio summary</h4>
                                    <div className="pt-3 position-relative">
                                        <span className="e-current-title">Current (Invested)
                                            <img src={require("../../assets/images/dataFormating/Icon.png").default}
                                                alt="Icon"
                                                className='ms-2'
                                                style={{ cursor: "pointer" }}
                                                onMouseEnter={() => setTooltip(true)}
                                                onMouseLeave={() => setTooltip(false)} />
                                        </span>
                                        <p className="e-current-value">{summaryData && Object.values(summaryData).length > 0 && summaryData.current_amount ? `${(summaryData.current_amount)} (${(summaryData.invested_amount)})` : "0"}</p>
                                        {
                                            tooltip ?
                                                <IconStyleToolTip
                                                    className="e-current-tooltip mt-4"

                                                    hover={() => { }}
                                                    content={`Current value breakdown<br/><hr/>
                                                    - Invested:<span class="float-end"> ${summaryData.invested_amount}</span><br/>
												- Redemption: <span class="float-end">${summaryData.total_redemption}</span><br/>
												On Rebalancing: <span class="float-end"> ${summaryData.redemption_from_rebalance}</span><br/>
												By Customer:<span class="float-end"> ${summaryData.normal_redemption}</span>
												`} />

                                                :
                                                null
                                        }
                                    </div>
                                    <div className="pt-2">
                                        <span className="e-current-title">Total returns</span>
                                        <p >{summaryData && Object.values(summaryData).length > 0 && summaryData.p_and_l ? (summaryData.p_and_l) : 0}</p>
                                    </div>
                                    <div className="pt-2">
                                        <span className="e-current-title">Amount invested</span>
                                        <p >{summaryData && Object.values(summaryData).length > 0 && summaryData.invested_amount ? (summaryData.invested_amount).toLocaleString('en-IN') : 0}</p>
                                    </div>
                                    <div className="pt-2">
                                        <span className="e-current-title">XIRR</span>
                                        <p style={summaryData.xirr_value > 0 ? { color: "green" } : { color: "red" }}>{summaryData && Object.values(summaryData).length > 0 && summaryData.invested_amount ? summaryData.xirr : 0}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-8 col-12 mt-lg-0 mt-md-3 mt-3">
                                <div className="card e-dashboard-card border-0 e-profile-holdings position-relative">
                                    <h4 className=''>Plan overview
                                        {/* <img
                                            className="ps-2"
                                            src={Icon} ref={wrapperRef} alt=""
                                            onMouseEnter={() => setTooltip(true)}
                                            onMouseLeave={() => setTooltip(false)}
                                            style={{ cursor: "pointer" }}
                                        /> */}
                                    </h4>

                                    <div className='row'>
                                        {
                                            loader ?
                                                <Loader />
                                                :
                                                performanceData.length > 0 && labels.length > 0 && images.length > 0 && targetData.length > 0 ?
                                                    <>
                                                        <div className='col-lg-6'>

                                                            {/* <GradientChart
                                                                performace={performanceData}
                                                                categories={labels}
                                                                images={images}
                                                                target={targetData}
                                                            /> */}
                                                            <LineChartMarker

                                                                performace={performanceData}
                                                                categories={labels}
                                                                images={images}
                                                                target={targetData} />
                                                        </div>
                                                        <div className='col-lg-5'>
                                                            <div className="e-graph-data-wrapper1">
                                                                <div className="d-inline-flex" style={{ width: "100%" }}>
                                                                    <span className="e-graph-class e-target-class"></span>
                                                                    Target

                                                                </div>
                                                                <div className="d-inline-flex" style={{ width: "100%" }}>
                                                                    <span className="e-graph-class e-performance-class"></span>
                                                                    Current performance
                                                                </div>
                                                            </div>
                                                            <div className="e-graph-data-wrapper2 mt-2">
                                                                {
                                                                    goalData.map((item) => {
                                                                        return (

                                                                            <div className="row">
                                                                                <div className="col-lg-2 col-2 pe-0">
                                                                                    <img src={item.icon} alt="" width="38px" />
                                                                                </div>
                                                                                <div className="col-lg-10 col-10 ps-0 pt-2 mt-1 ps-2">
                                                                                    <p className="ps-0">{item.name}</p>
                                                                                </div>
                                                                            </div>


                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <EmptyNote note="No data" />
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                    </>

            }
        </>

    )
}

export default Holdings;

