/*
 *   File : addFund.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : edit fund modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import components*/
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { AggressiveFund } from '../../components/card/index';
import { Datepicker, Input, SearchInput } from '../../components/input/index';
import { Toast } from '../note';
import { Loader } from '../loader';

/*import styles*/
import modalStyle from '../../styles/components/confirmation.module.scss';
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import SearchFunds from '../../assets/images/dataFormating/search-funds.svg';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function AddFund(props) {
	const [search, setSearch] = useState("");
	const [getFundId, setGetFundId] = useState(null);
	const [funds, setFunds] = useState([]);
	const [error, setError] = useState(null);
	const [response, setResponse] = useState(null);
	const [searchLoader, setSearchLoader] = useState(false);
	const [fundSelected, setFundSelected] = useState({});
	const [selected, setSelected] = useState(false);
	const [date, setDate] = useState(null);
	const [percent, setPercent] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loader, setLoader] = useState(false);
	const [assetClass, setAssetClass] = useState("");
	const [fundAlias, setFundAlias] = useState(null);

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success]);

	useEffect(() => {
		var myModalEl = document.getElementById('addFund')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			//empty values on modal close
			setPercent(null);
			setDate(null);
			setSelected(false);
			setFundSelected({});
			setSearch("");
			setResponse(null);
			setFunds([])
			setGetFundId(null);
			setAssetClass("");
			$(".modal-backdrop").remove()
			$("body").removeAttr("style")
		})
		//eslint-disable-next-line
	}, [])


	/* ---- search funds api ---*/
	const searchFunds = (query) => {
		setSearchLoader(true)
		let url = devconfig.admin + '/fund/search'

		let data = JSON.stringify({
			query: query ? query : search,
			page_num: 1,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setFunds(response.data);
				setResponse(response.message);
				setSearchLoader(false);
			}
			else {
				setError(response.message);
				setSearchLoader(false);
			}
		});
	}

	/*-----add maturity fund api---- */
	const addMaturityFund = () => {
		setLoader(true);
		let url = devconfig.admin + '/fund/tm-manage';
		let data = JSON.stringify({
			"id": null,
			"scheme_code": getFundId,
			"maturity_date": date,
			"ytm": percent,
			"asset_class": assetClass,
			"fund_alias": fundAlias
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				setSuccess("Fund added Successfully");
				setTimeout(() => {
					$('#addFund').trigger('click');
					$("#close-btn").trigger("click");
					props.updatedFund(true);
				}, 1000);
			}
			else {
				setError(response.message);
				setLoader(false);
				setTimeout(() => {
					$('#addFund').trigger('click');
				}, 1000);
			}
		});
	}

	return (

		<div className={`${modalStyle.e_confirmation} modal fade`} id="addFund" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h6>Add fund</h6>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							id="close-btn"
							onClick={() => {
								setSearch("");
								setResponse(null);
								setFunds([])
							}}>
						</button>
					</div>
					{
						selected ?
							<div className='container px-3 position-relative' style={{ height: "50vh", overflowY: "scroll", overflowX: "hidden" }}>
								<div className='row'>
									<div className='col-12'>
										<div className={modalStyle.e_selected_fund}>
											{fundSelected.scheme_name}{` (${fundSelected.isin})`}
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-lg-12 mb-3'>
										<label className='mb-1'>Asset class</label>
										<Input
											placeholder="Eg: Indian equities"
											type="text"
											value={assetClass}
											valueChange={(value) => {
												setAssetClass(value)
											}}
										/>
									</div>
									<div className='col-lg-6'>
										<label className='mb-1'>Maturity date</label>
										<Datepicker
											value={date}
											onChange={(value) => {
												setDate(value)
											}}
										/>
									</div>
									<div className='col-lg-3'>
										<label className='mb-1'>YTM(%)</label>
										<Input
											type="number"
											placeholder="Enter the percentage"
											value={percent}
											valueChange={(value) => {
												setPercent(parseFloat(value));
											}}
										/>
									</div>
									<div className='col-3'>
										<label className='mb-1'>Fund alias</label>
										<Input type="text"
											value={fundAlias}
											placeholder="Alias fund"
											valueChange={(value) => {
												setFundAlias(value);
											}}
										/>
									</div>
								</div>
								<div className='row pt-4 pb-2  position-absolute bottom-0 end-0'>
									<div className="col-lg-12">
										<SecondaryBtn
											name="Cancel"
											className="e-cancel"
											cancel="modal"
											handleClick={() => {
												setSearch("");
												setResponse(null);
												setFunds([]);
											}} />
										{
											percent && date && assetClass && fundAlias ?
												<PrimaryBtn
													name="Save fund"
													className={
														loader ?
															"e-modal-submit e-btn-loader"
															:
															"e-modal-submit"
													}
													handleClick={() => {
														addMaturityFund()
													}}
												/>
												:
												<PrimaryBtn
													name="Save fund"
													className="e-modal-submit e-disabled"
												/>
										}
									</div>
								</div>
							</div>
							:
							<>
								<div className="container px-3" style={{ height: "50vh", overflowY: "scroll", overflowX: "hidden" }}>
									<div className="row">
										<div className="col-lg-12 position-relative mb-4">
											<SearchInput
												placeholder="Search for funds"
												value={search}
												className={modalStyle.e_search}
												valueChange={(value) => {
													setSearch(value);
												}}
												onSearch={(value) => {
													searchFunds(value)
												}} />
											{
												search && search.length ?
													<>
														<span
															className={`${styles.e_search_submit} `}
															onClick={() => searchFunds()}>
														</span>
														<button
															className={`${styles.e_search_close} pe-3`}
															onClick={() => {
																setSearch("");
																setResponse(null);
																setFunds([]);
																setGetFundId(null);
															}} >
														</button>
													</>
													:
													null
											}

										</div>
									</div>
									{
										response !== null ?
											funds?.length > 0 ?
												<div className="row">
													<p>Results</p>
													{
														funds.map((item, key) => {
															return (
																<>
																	<div className="col-lg-6 mb-3 pb-2" key={key} >
																		<AggressiveFund
																			maturity={true}
																			data={item}
																			selectedFund={getFundId}
																			selectedFundData={(temp) => {
																				setGetFundId(temp);
																			}}
																			selectFundObj={(item) => {
																				setFundSelected(item)
																				console.log(item)
																			}} />
																	</div>
																</>
															)
														})
													}
												</div>
												:
												<div className="row">
													<h6 className="text-center"> No Mutual Funds Found </h6>
												</div>
											:
											searchLoader === false ?
												<div align="center" className='mt-4'>
													<img src={SearchFunds} alt="" />
													<p className={modalStyle.e_search_fund}>Search Funds</p>
												</div>
												:
												<Loader />
									}

								</div>
								<div className="row pt-3">
									<div className="col-lg-12 d-flex justify-content-end">
										{/* <div className="d-flex justify-content-end"> */}
										<SecondaryBtn
											name="Cancel"
											className="e-cancel"
											cancel="modal"
											handleClick={() => {
												setSearch("");
												setResponse(null);
												setFunds([]);
											}} />
										{
											getFundId ?
												<PrimaryBtn
													name="Save fund"
													className="e-modal-submit"
													handleClick={() => {
														setSelected(true)
													}}
												/>
												:
												<PrimaryBtn
													name="Save fund"
													className="e-modal-submit e-disabled"
												/>
										}
										{/* </div> */}
									</div>
								</div>
							</>
					}
				</div>
			</div>

			{/* Error toast */}
			{
				error !== null ?
					<Toast
						data={error}
						type="error"
						id="1"
					/>
					:
					null
			}

			{/* success toast */}
			{
				success !== null ?
					<Toast
						data={success}
						type="success"
						id="2"
					/>
					:
					null
			}
		</div>
	);
}
