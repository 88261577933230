/*
 *   File : download.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Dowload Button
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*Import Packages*/
import React from 'react';

/*import style*/
// import styles from '../../styles/components/download.module.scss';

/* import assets */
import Download from '../../assets/images/cta/download.svg';

export default function DownloadBtn(props) {
	return (
		<>
			{/* <button
				onClick={props.onClick}
				className={ !props.optionalDisable ?  props?.check?.length > 0 ? `${styles.e_download_btn_active}` : `${styles.e_download_btn}`  : `${styles.e_download_btn_active}` }
			>
				<span>Download report</span>
				<span className="ps-1"> */}
			<img src={Download} alt="download"
				width={20}
				height={20}
				className={`cursor-pointer ${props.loader ? "bounce" : ""}`}
				onClick={props.onClick} />
			{/* </span>
			</button> */}
		</>

	);
}
