/*
 *   File : multi-select.js
 *   Author URI : www.evoqins.com
 *   Description : Multi select
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

import { Fragment, useEffect, useRef, useState } from "react";
import { PrimaryBtn } from "../cta";


import style from '../../styles/components/multi-select.module.scss'
const CustomMultiSelect = (props) => {
    const dropdownRef = useRef(false);
    const [value, setValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setSelected(props.selected);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props.selected]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    function _handleShowDropdown() {
        setShowDropdown(true)
    }

    function _handleSelectFund(item) {
        let selected_list = [...selected]; // Create a copy of the selected list

        // Find index of the item in the selected list
        const index = selected_list.findIndex((investor) => investor.value === item.value);

        if (item.value === null) {
            // If "All" is selected, clear the selected list and only select "All"
            selected_list = [props.data[0]];
        } else {
            // If any other item is selected, deselect "All" and toggle the item
            const allIndex = selected_list.findIndex((item) => item.value === null)
            if (allIndex >= 0) {
                // Remove "All" if it's selected
                selected_list.splice(allIndex, 1);
            }
            if (index < 0) {
                // If the item is not in the selected list, add it
                selected_list.push(item);
            } else {
                // If the item is already selected, remove it
                selected_list.splice(index, 1);
            }
        }

        setSelected(selected_list);
    }

    return (
        <div className={`${style.e_multi_select}  w-100 position-relative`}>
            <input type="text"
                id={props.id}
                spellCheck="false"
                placeholder={props.selected.length === 0 ? props.placeHolder : ""}
                autoComplete="off"
                readOnly={true}
                onFocus={() => _handleShowDropdown()}
                className={`${props.className}  `}
            />

            {
                props.selected.length > 0 &&
                <div className={`position-absolute cursor-pointer ${style.e_dropdown_value}`} onClick={_handleShowDropdown}>
                    {
                        props.selected.map((item, index) => {
                            return (
                                <span>{index > 0 ? " , " : ""} {item.label}</span>
                            )
                        })
                    }
                </div>
            }

            {
                showDropdown &&
                <Fragment>
                    <div className={`position-absolute ${style.e_dropdown}`} ref={dropdownRef}>
                        <div className={`${style.e_dropdown_wrapper}`}>
                            {
                                props.data.length > 0 ? props.data.map((item) => {
                                    return (
                                        <p className="p-2 mb-0 d-flex cursor-pointer" onClick={() => _handleSelectFund(item)}>
                                            <img src={
                                                selected.some((investor) => investor.value === item.value) ? require("../../assets/images/cta/checked.svg").default : require("../../assets/images/cta/unchecked.svg").default} width={16}
                                                className='me-2' /> {item.label}
                                        </p>
                                    )
                                })
                                    :
                                    <p>No data found</p>
                            }
                        </div>
                        <div className="text-end pe-3 pb-2 bg-white ">
                            <PrimaryBtn name="Apply" className="px-3 py-2 ms-auto"
                                handleClick={() => {
                                    props.select(selected);
                                    setShowDropdown(false);
                                }} />
                        </div>
                    </div>
                </Fragment>
            }
        </div>

    )

}

export default CustomMultiSelect