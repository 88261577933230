/*
 *   File : xray.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 20-01-2022
 *   Version : v1.0
 *   Created : 10-01-2025
 */


const MIRAXray = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card e-dashboard-card border-0 py-1">
                    <div className="row ">
                        <div className="col-12">
                            <div style={{ width: '100%', height: '100vh' }}>
                                <iframe
                                    src="https://mira-xray.evoqins.dev/reports/index.html"
                                    title="Report"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                    }}
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MIRAXray