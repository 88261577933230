/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Orders container;
 *   Integrations :  12-08-2022
 *   Version : v1.0
 *   Created :  12-09-2022
*/
/*import packages*/
import { useState, useEffect, useRef, Fragment } from 'react';
import devconfig from '../../config/config';
import { EmptyNote } from '../../components/note';
import { DataTable } from '../../components/dataFormating';
import { Loader } from '../../components/loader';
import APIPost from '../../services/postApi';
import { useNavigate } from 'react-router-dom';
import SelectBox from '../../components/dropdown/selectBox';
import APIGet from '../../services/getApi';


const ExternalTransactions = (props) => {
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const [loader, setLoader] = useState(true);

    const [totalRecords, setTotalRecords] = useState(null);

    useEffect(() => {
        getExternalTransactions();

        //eslint-disable-next-line
    }, [perPage, pageNum]);

    const columns = [
        {
            name: 'Scheme name',
            cell: (row) => row.rta_scheme_name ? row.rta_scheme_name : '--',
            sortable: true,
            left: true,
            width: "450px",
            defaultSortAsc: false,
        },
        {
            name: 'Created on',
            selector: row => row.created,
            sortable: false,
            left: true,
            width: '150px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_time',),
            cell: row => <div className="col-lg-12" >
                {row.created}
            </div>
        },
        {
            name: 'Type',
            selector: row => row.order_type,
            sortable: true,
            left: true,
            width: "150px",
            cell: (row) => <div className="col-lg-12">
                {row.order_type}
            </div>
        },
        {
            name: 'Folio No.',
            selector: row => row.folio,
            sortable: true,
            left: true,
            width: '150px',
            cell: (row) => <div className="col-lg-12">
                {row.folio_number ? row.folio_number : "-"}
            </div>
        },
        {
            name: 'Traded on',
            selector: row => row.traded_on,
            sortable: false,
            left: true,
            width: '200px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_time',),
            cell: row => <div className="col-lg-12" >
                {row.traded_on} {row.traded_at}
            </div>
        },
        {
            name: 'Units',
            selector: row => row.units,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            width: '130px',
            cell: row => <div>
                {row.units ? row.units : "-"}
            </div>

        },
        {
            name: 'Amount',
            selector: row => row.amount ? parseFloat(row.amount) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div>
                ₹{
                    row.amount ?
                        row.amount.toFixed(2)
                        :
                        0
                }
            </div>

        },
        {
            name: 'Transaction ID',
            selector: row => row.rta_order_reference,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            width: '180px',
            cell: row => <div>
                {row.rta_order_reference}
            </div>

        },


    ]

    const _handlePageChange = (page_num) => {
        setPageNum(page_num);
    };

    const _handlePerPage = (per_page) => {
        setPerPage(per_page);
    };

    /*--------- order list -----------*/
    const getExternalTransactions = (query) => {
        let url = devconfig.admin + '/order/external-transaction';

        let data = JSON.stringify({
            user_id: Number(props.id),
            page_num: pageNum,
            page_size: perPage,

        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data.data);
                setTotalRecords(response.data.total_records)
            } else {
                setOrderData([]);
            }
            setLoader(false);
        });
    }

    return (
        <Fragment>
            <div className="row pe-0">
                <div className="col-lg-12 pe-0">
                    <div className="card e-dashboard-card border-0 py-1 px-0">
                        {
                            loader ?
                                <Loader />
                                :
                                <div className="row pt-2 ">
                                    <div className="col-lg-12">
                                        <h6 className="e-section-title mb-0 px-3">External Transactions
                                            {/* <TextBtn name="View Summary"
										className="ms-2"
										handleClick={() => _handleShowSummary(true)} /> */}
                                        </h6>
                                    </div>
                                </div>
                        }
                        {/*--------------- Order table -----------*/}
                        {
                            (!orderData.length) ?
                                <EmptyNote note="There are external transactions to show" />
                                :
                                <div className="col-lg-12 px-0">
                                    <DataTable
                                        pagination={true}
                                        columns={columns}
                                        data={orderData}
                                        perPage={perPage}
                                        paginationServer={true}
                                        selectableRows={false}
                                        paginationTotalRows={totalRecords}
                                        onChangeRowsPerPage={_handlePerPage}
                                        onChangePage={_handlePageChange}
                                        selectRows={() => { }} />
                                </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ExternalTransactions