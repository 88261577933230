/*
 *   File : profileKyc.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Profile Kyc Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 13-12-2021
 */

/*import packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/profileKyc.module.scss';

/*import assets*/
import Yes from '../../assets/images/card/tick.svg';
import { PrimaryLink } from '../cta';
// import Edit from '../../assets/images/card/edit.png';

const ProfileKyc = (props) => {
	return (
		<>
			<div className={`${styles.e_profileKyc_card} card h-100`} id={props.id}>
				<div className="row">
					<div className="col-lg-12 col-12 text-start d-inline-flex ps-0 pb-2">
						<h5>{props.title}</h5>
						{
							props.verified ?
								<span className={`${styles.e_verified}`}>
									<img src={Yes} className="me-1" alt="" />{props.data.kyc.kyc_status}
								</span>
								:
								null
						}
						{
							props.type === 3 && Object.values(props.data.bank).length > 0 && props.data.bank.account ?

								<span className={`${styles.e_verified} ms-auto mb-2`}>
									<PrimaryLink name="Update details"
										toggle="modal"
										target="#updateBankDetail" />
								</span>

								:
								null
						}
						{/* {
							props.edit ?
								<img src={Edit} className={`${styles.e_edit_icon} position-absolute`} onClick={() => { props.Cliked(props) }} alt="edit"/>
								:
								null
						} */}
					</div>
					{
						props.type === 1 ?
							<div className={`${styles.e_content_wrap}`}>
								<div className="row">
									<div className="col-lg-4 col-4 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Name
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.name && props.data && props.data.overview ?
													props.data.overview.name
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											DOB
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.dob && props.data && props.data.overview ?
													props.data.overview.dob
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-3 ">
										<h5 className={`${styles.e_content_title}`}>
											Marital status
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.marital_status && props.data && props.data.overview ?
													props.data.overview.marital_status
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Gender
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.gender && props.data && props.data.overview ?
													props.data.overview.gender
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Phone
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.phone && props.data && props.data.overview ?
													props.data.overview.phone
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-3">
										<h5 className={`${styles.e_content_title}`}>
											Occupation
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.occupation && props.data && props.data.overview ?
													props.data.overview.occupation
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-0">
										<h5 className={`${styles.e_content_title}`}>
											Email
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.email && props.data && props.data.overview ?
													props.data.overview.email
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-0">
										<h5 className={`${styles.e_content_title}`}>
											Source of wealth
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.wealth_source && props.data && props.data.overview ?
													props.data.overview.wealth_source
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-lg-4 col-4 mb-0">
										<h5 className={`${styles.e_content_title}`}>
											Annual income
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.annual_income && props.data && props.data.overview ?
													`₹ ${props.data.overview.annual_income.toLocaleString('en-IN')}`
													:
													"-"
											}
										</h5>
									</div>
								</div>
							</div>
							: props.type === 2 ?
								<div className={`${styles.e_content_wrap} h-100`}>
									<div className="row">
										<div className="col-lg-4 col-5 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												PAN
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.kyc.pan && props.data && props.data.kyc ?
														props.data.kyc.pan
														:
														"-"
												}
											</h5>
										</div>
										<div className="col-lg-4 col-5 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												Country
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.kyc.country && props.data && props.data.kyc ?
														props.data.kyc.country
														:
														"-"
												}
											</h5>
										</div>
										<div className="col-lg-4 col-2 mb-3">
											{
												props.data && props.data.overview ?
													<>
														<h5 className={`${styles.e_content_title}`}>
															KYC Complaint
														</h5>
														<h5 className={`${styles.e_content}`}>
															{props.data.overview.kyc_complaint ? "Yes" : "No"}

														</h5>
													</>
													:
													null
											}
										</div>
										<div className="col-lg-4 col-5 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												Name on PAN
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.name_on_pan && props.data && props.data.overview ?
														props.data.overview.name_on_pan
														:
														"-"
												}
											</h5>
										</div>
										<div className="col-lg-4 col-5 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												Name in bank account
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.bank_account_name && props.data && props.data.overview ?
														props.data.overview.bank_account_name
														:
														"-"
												}
											</h5>
										</div>
										<div className="col-lg-4 col-5 mb-3">
											<h5 className={`${styles.e_content_title}`}>
												Name match
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.name_match_percent && props.data && props.data.overview ?
														props.data.overview.name_match_percent.toLocaleString()
														:
														"-"
												}
												{
													props.data.overview.name_mismatch && props.data && props.data.overview ?
														"⚠️"
														:
														""
												}
											</h5>
										</div>
										<div className="col-lg-4 col-5 mb-3 ">
											<h5 className={`${styles.e_content_title} mb-1`}>
												Permanent address
											</h5>
											{
												props.data && props.data.kyc && props.data.kyc.address && props.data.kyc.address.permanent_address && Object.values(props.data.kyc.address.permanent_address).length > 0 ?
													<p className={`${styles.e_content} mb-1`}>
														{props.data.kyc.address.permanent_address.address_line1}

														<p className='mb-0'>{props.data.kyc.address.permanent_address.address_line2}</p>
														<p className='mb-1'>
															{`${props.data.kyc.address.permanent_address.city ? `${props.data.kyc.address.permanent_address.city},` : ""} ${props.data.kyc.address.permanent_address.state ? `${props.data.kyc.address.permanent_address.state},` : ""}
															${props.data.kyc.address.permanent_address.pincode ? `${props.data.kyc.address.permanent_address.pincode}` : ""}`}

														</p>

													</p>
													:
													"--"
											}

										</div>
										<div className="col-lg-4 col-5 mb-3">
											<h5 className={`${styles.e_content_title} mb-1`}>
												Correspondence address
											</h5>
											{
												props.data && props.data.kyc && Object.keys(props.data.kyc).length > 0 && props.data.kyc.address.permanent_address && props.data.kyc.address && Object.values(props.data.kyc.address.correspondence_address).length > 0 ?
													<p className={`${styles.e_content} mb-1`}>
														{props.data.kyc.address.correspondence_address.address_line1}

														<p className='mb-0'>{props.data.kyc.address.correspondence_address.address_line2}</p>
														<p className='mb-1'>
															{`${props.data.kyc.address.correspondence_address.city ? `${props.data.kyc.address.correspondence_address.city},` : ""} ${props.data.kyc.address.correspondence_address.state ? `${props.data.kyc.address.correspondence_address.state},` : ""}
															${props.data.kyc.address.correspondence_address.pincode ? `${props.data.kyc.address.correspondence_address.pincode}` : ""}`}

														</p>

													</p>
													:
													props.data && props.data.kyc && props.data.kyc.address && Object.keys(props.data.kyc).length > 0 && props.data.kyc.address.permanent_address && Object.values(props.data.kyc.address.permanent_address).length > 0 ?
														<p className={`${styles.e_content} mb-1`}>
															{props.data.kyc.address.permanent_address.address_line1}

															<p className='mb-0'>{props.data.kyc.address.permanent_address.address_line2}</p>
															<p className='mb-1'>
																{`${props.data.kyc.address.permanent_address.city ? `${props.data.kyc.address.permanent_address.city},` : ""} ${props.data.kyc.address.permanent_address.state ? `${props.data.kyc.address.permanent_address.state},` : ""}
															${props.data.kyc.address.permanent_address.pincode ? `${props.data.kyc.address.permanent_address.pincode}` : ""}`}

															</p>

														</p>
														:
														"--"
											}

										</div>
										<div className="col-lg-6 col-12">
											{
												props.data && props.data.kyc && Object.keys(props.data.kyc).length > 0 && props.data.kyc.rejected_reasons.length > 0 ?
													<>
														<h5 className={`${styles.e_content_title} mb-1`}>
															KYC Rejected reasons
														</h5>
														<ul className='mb-0 ps-3'>
															{
																props.data.kyc.rejected_reasons.map((item) => {
																	return (
																		<li className={styles.e_content}>{item}</li>
																	)
																})
															}
														</ul>
													</>
													:
													null
											}
										</div>
										{/* <div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title5}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item5}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title6}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item6}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title7}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item7}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title8}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item8}</h5>
										</div>
										<div className="col-lg-4 col-12">
											<h5 className={`${styles.e_content_title}`}>{props.data.title9}</h5>
											<h5 className={`${styles.e_content}`}>{props.data.item9}</h5>
										</div> */}
									</div>
								</div>
								: props.type === 3 ?
									<div className={`${styles.e_content_wrap} `}>
										<div className="row">

											<div className="col-lg-6 col-6 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													Bank
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.name ?
															props.data.bank.name
															: "--"
													}
												</h5>
											</div>
											<div className="col-lg-4 col-4 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													Account No.
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.account ?
															props.data.bank.account
															: "--"
													}
												</h5>
											</div>
											<div className="col-lg-6 col-6 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													IFSC
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.ifsc ?
															props.data.bank.ifsc
															: "--"
													}
												</h5>
											</div>
											<div className="col-lg-6 col-6 mb-3">
												<h5 className={`${styles.e_content_title}`}>
													Branch
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.branch ?
															props.data.bank.branch
															: "--"
													}
												</h5>
											</div>

										</div>
									</div>
									: props.type === 4 ?
										<div className={`${styles.e_content_wrap} `}>
											<div className="row">
												<div className="col-lg-6 col-6 mb-3">
													<h5 className={`${styles.e_content_title}`}>
														Bank
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.name ?
																props.data.bank.name
																: "--"
														}
													</h5>
												</div>
												<div className="col-lg-6 col-6 mb-3">
													<h5 className={`${styles.e_content_title}`}>
														Type
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.name ?
																props.data.bank.bank_account_type
																: "--"
														}
													</h5>
												</div>
												<div className="col-lg-6 col-6 mb-3">
													<h5 className={`${styles.e_content_title}`}>
														ID
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.autopay_id ?
																props.data.bank.autopay_id
																: "--"
														}
													</h5>
												</div>

												<div className="col-lg-6 col-6 mb-4 pb-2">
													<h5 className={`${styles.e_content_title}`}>
														Auto-debit
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 ?
																props.data.bank.autopay_id !== null ?
																	"On"
																	: "Off"
																:
																"--"
														}
													</h5>
												</div>

											</div>
										</div>
										:
										null
					}
				</div>
			</div>
		</>
	);
}

export default ProfileKyc