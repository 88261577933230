/*
 *   File : orderDetail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order detail Modal
 *   Integrations : 24-05-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect, Fragment } from 'react';
import $ from 'jquery'

/*import componets*/
import { Badge } from '../../components/badge/index';
import EmptyNote from '../note/empty';
import Tooltip from '../../components/badge/tooltip';

/*import styles*/
import styles from '../../styles/components/transactionDetail.module.scss';
import { Loader } from '../loader';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { useNavigate } from 'react-router-dom';
import { Input } from '../input';
import { PrimaryBtn, SecondaryBtn } from '../cta';
import { Toast } from '../note';
import { toast } from 'react-toastify';



export default function OrderDetail(props) {
    const navigate = useNavigate()
    const [orderData, setOrderData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [show, setShow] = useState(null);
    const [orderIds, setOrderIds] = useState([]);

    useEffect(() => {
        if (Object.keys(props.data).length > 0) {
            orderDetail()
        }
        //eslint-disable-next-line
    }, [props.data.order_id]);

    useEffect(() => {
        var my_modal = document.getElementById("orderDetail");
        my_modal.addEventListener("hidden.bs.modal", () => {
            setOrderIds([]);
        });
        //eslint-disable-next-line
    }, []);

    function _handleOrderIds(id) {
        const order_ids = [...orderIds];
        const order_index = order_ids.findIndex((item) => item === id);
        if (order_index < 0) {
            order_ids.push(id);
        } else {
            order_ids.splice(order_index, 1);
        }

        setOrderIds(order_ids)
    }

    const orderDetail = () => {
        setLoader(true)
        let url = ""
        let data = {}
        if (props.id) {
            url = devconfig.admin + "/order/detail";
        } else {
            url = devconfig.admin + "/order/detail";
        }

        if (props.id) {
            data = JSON.stringify({
                user_id: Number(props.id),
                order_id: props.data.order_id
            })
        } else {
            data = JSON.stringify({
                order_id: props.data.order_id
            })
        }

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data);
            } else {
                setOrderData([]);
            }
            setLoader(false)
        })
    }

    // API -place order 
    const _placeOrder = () => {
        setApiLoader(true);

        const url = devconfig.admin + "/order/place-order";
        const data = JSON.stringify({
            order_ids: orderIds
        })

        APIPost(url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Order placed successfully", {
                    type: "success"
                })
                setApiLoader(false);
                $("#close-btn").trigger("click");
                props.updateList();
                orderDetail();
                setOrderIds([]);
            } else {
                toast.error(response.message, {
                    type: "error"
                })
                setApiLoader(false)
            }
        })
    }
    return (
        <>

            <div className={`${styles.e_transaction_detail} modal fade e-order-detail-modal mb-5`} id="orderDetail" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className={`${styles.e_dialog} modal-dialog modal-dialog-centered`}>
                    <div className={`${styles.e_modal_content} modal-content px-0`}>
                        <div className="modal-header p-4 pb-3 ">

                            <div className='position-relative'>
                                <h6 className={`${styles.e_modal_title} pt-3 `}>Order details
                                </h6>
                            </div>
                            <button id="close-btn" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {
                            loader ?
                                <Loader />
                                :

                                orderData.length > 0 ?
                                    <Fragment>
                                        <div className='e-order-detail '>
                                            <div className={`${styles.e_transaction_content}`}>
                                                <div className="row p-4 ps-3">
                                                    <div className="col-lg-5 col-5 pe-0">
                                                        <div className='row'>
                                                            <div className='col-3 px-0 d-flex align-items-center justify-content-end'>
                                                                <div className={`${styles.e_icon} d-flex align-items-center justify-content-center`}>
                                                                    {props?.data?.name ? props?.data?.name?.charAt(0) : props.fName?.charAt(0)}
                                                                </div>
                                                            </div>
                                                            <div className='col-9 px-0'>
                                                                <div className={`${styles.e_span} ms-3 mt-2 `}>
                                                                    {
                                                                        props.fName ?
                                                                            <h6 className='mb-1'>{props.fName}</h6>
                                                                            :
                                                                            <h6 className='mb-1 ' onClick={() => {
                                                                                navigate(`/user-detail?id=${props.data.customer_id}`)
                                                                                $(".btn-close").trigger("click")
                                                                            }}>
                                                                                <span className='e-link'>{props.data.name}</span>

                                                                            </h6>
                                                                    }
                                                                    <p className={`${styles.e_time_date}  mb-0`}>{props.id ? props.data.created : `${props.data.order_time}`}</p>
                                                                    <p className={`${styles.e_time_date}  mb-0`}>{props.id ? props.data.mira_order_id : props.data.order_no}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3 text-start px-0 position-relative mt-2">

                                                        <h6 className='mb-0 pt-0 '>

                                                            Payment status
                                                            <span onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}
                                                                className={
                                                                    props.payment_status === "Failed" ? `e-icon` :
                                                                        props.payment_status === "Processing" ? `e-icon` :
                                                                            null
                                                                }></span>
                                                            {
                                                                show ?
                                                                    props.id ?
                                                                        <Tooltip
                                                                            className={styles.e_tooltip}
                                                                            content={
                                                                                props.data.payment_failed_reason ?
                                                                                    props.data.payment_failed_reason :
                                                                                    props.payment_status === "Processing" ?
                                                                                        "Transaction pending"
                                                                                        :
                                                                                        "Transaction failed"
                                                                            } />
                                                                        :
                                                                        <Tooltip
                                                                            className={styles.e_tooltip}
                                                                            content={
                                                                                props.data.failure_reason ?
                                                                                    props.data.failure_reason :
                                                                                    props.payment_status === "Processing" ?
                                                                                        "Transaction pending"
                                                                                        :
                                                                                        "Transaction failed"
                                                                            } />

                                                                    :
                                                                    null
                                                            }
                                                        </h6>
                                                        {
                                                            props.payment_status !== null ?
                                                                <Badge type={props.payment_status}
                                                                    text={props.payment_status.toLowerCase()}
                                                                    className={`${styles.e_badge}`}
                                                                    style={{ fontSize: "11px" }} />
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-lg-2 text-start px-0 mt-2">
                                                        <h6 className='mb-0 pt-0 '>Order status</h6>
                                                        {
                                                            props.order_status !== null ?
                                                                <Badge type={props.order_status}
                                                                    text={props.order_status.toLowerCase()}
                                                                    className={`${styles.e_badge}`}
                                                                    style={{ fontSize: "11px" }} />
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-lg-2 text-start mt-2">
                                                        <h6 className='mb-0 pb-1 pt-0 '>Type</h6>
                                                        <p>{props.data.type}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                props.data.type === "SWITCH" || props.data.type === "Switch" ?
                                                    <Fragment>
                                                        <div className='container-fluid ps-3'>
                                                            <div className='row mt-4 pe-3 ps-1'>
                                                                <div className={`col-lg-4 ${styles.e_details} mb-2`}>
                                                                    <h6>Transaction ID:</h6>
                                                                </div>
                                                                <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                                                                    <h6>Amount</h6>
                                                                </div>
                                                                <div className={`col-lg-3 ${styles.e_details} mb-2`}>
                                                                    <h6>Date</h6>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2 `}>
                                                                    <h6>Status</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            orderData.map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <div className={`container-fluid px-3`}>
                                                                            <div className={orderData.length === key + 1 ?
                                                                                key % 2 !== 0 ? `border-bottom row pt-3 mb-3  pe-4 ps-1 ` : `border-bottom e-order-row row pt-3 mb-3  pe-4 ps-1`
                                                                                :
                                                                                key % 2 !== 0 ? `row pt-3 mb-3  pe-4 ps-1` : `e-order-row row pt-3 mb-3  pe-4 ps-1`}>
                                                                                <div className={`col-lg-4 ${styles.e_details} mb-3`}>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <p className={`${styles.e_transaction_id} ms-2 mb-2`}>{item.transaction_id} </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={`col-lg-3 ${styles.e_details} mb-3 px-0`}>
                                                                                    <p className={`${styles.e_transaction_id} mb-0`}>₹{parseFloat(item.amount?.split("₹")[1])?.toFixed(2)}</p>
                                                                                </div>
                                                                                <div className={`col-lg-3 ${styles.e_details} mb-3`}>
                                                                                    <p className={`${styles.e_transaction_id} mb-0`}>{item.transaction_date} </p>
                                                                                </div>
                                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>

                                                                                    <Badge type={item.status}
                                                                                        text={item.status?.toLowerCase()}
                                                                                        className={`${styles.e_badge}`}
                                                                                        style={{ fontSize: "11px" }} />
                                                                                </div>


                                                                                <h6 className={`${styles.e_details}`}>Switch Fund & Units:</h6>
                                                                                <div className={`col-lg-5 ${styles.e_details}`}>
                                                                                    <p className={`${styles.e_transaction_id}`}>
                                                                                        {item.switch_in_fund_name}
                                                                                        <br />
                                                                                        {item.switch_in_units}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-2 text-center'>
                                                                                    <img src={require("../../assets/images/cta/arrow-blue.svg").default}
                                                                                        width={"24px"} />
                                                                                </div>
                                                                                <div className={`col-lg-5 ${styles.e_details}`}>
                                                                                    <p className={`${styles.e_transaction_id}`}>
                                                                                        {item.switch_out_fund_name}
                                                                                        <br />
                                                                                        {item.switch_out_units}
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                        </div >
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.data.amount > 0 ?
                                                                <div className='row py-3'>
                                                                    <div className='col-lg-3 text-end'>
                                                                        <h6>Total</h6>
                                                                    </div>
                                                                    <div className='col-lg-2 '>
                                                                        <p className={`${styles.e_transaction_id}`}>
                                                                            ₹{props.data.amount?.toFixed(2)}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <div className='container-fluid ps-3'>
                                                            <div className='row mt-4 pe-3 ps-1'>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                                    <h6>Transaction ID:</h6>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2 px-0`}>
                                                                    <h6>Amount & units</h6>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2 px-0 `}>
                                                                    <h6 className='ps-4'>Invested in</h6>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2 px-0 text-center`}>
                                                                    <h6>FP Order ID</h6>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                                    <h6>Date</h6>
                                                                </div>
                                                                <div className={`col-lg-2 ${styles.e_details} mb-2 `}>
                                                                    <h6>Status</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            orderData.map((item, key) => {
                                                                return (
                                                                    <>
                                                                        <div className={`container-fluid px-3`}>
                                                                            <div className={orderData.length === key + 1 ?
                                                                                key % 2 !== 0 ? `border-bottom row pt-3 mt-1  pe-4 ps-1` : `border-bottom e-order-row row pt-3 mt-1  pe-4 ps-1`
                                                                                :
                                                                                key % 2 !== 0 ? `row pt-3 mt-1  pe-4 ps-1` : `e-order-row row pt-3 mt-1  pe-4 ps-1`}>
                                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        {props.order_status == "Partially Success" && (props.data.type == "LUMPSUM" || props.data.type == "WITHDRAW") && item.status === "Failed" &&
                                                                                            <Input type="checkbox"
                                                                                                id="e-select"
                                                                                                className="cursor-pointer"
                                                                                                checked={orderIds.includes(item.id)}
                                                                                                valueChange={() => _handleOrderIds(item.id)} />}
                                                                                        <p className={`${styles.e_transaction_id} ms-2 mb-2`}>{item.transaction_id} </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={`col-lg-2 px-0 ${styles.e_details} mb-2`}>
                                                                                    <p className={`${styles.e_transaction_id} mb-0 pb-0`}>₹{item.amount ? parseFloat(item.amount.split("₹")[1])?.toFixed(2) : 0} </p>
                                                                                    <p className={`${styles.e_transaction_id} mt-0 mb-0 pt-0`}>Units :{item.units} </p>
                                                                                    <p className={`${styles.e_transaction_id} mb-0 pt-0`}>Nav: {item.order_response ? item.order_response.purchased_price : "-"}</p>
                                                                                    <p className={`${styles.e_transaction_id} mb-0 pt-0`}>Nav date: {item.order_response ? item.order_response.traded_on : "-"}</p>
                                                                                </div>
                                                                                <div className={`col-lg-2 ${styles.e_details} mb-2 px-0`}>
                                                                                    <p className={`${styles.e_transaction_id} ps-4`}>{item.mutual_fund} </p>
                                                                                </div>
                                                                                <div className={`col-lg-2 ${styles.e_details} mb-2 px-0 text-center`}>
                                                                                    <p className={`${styles.e_transaction_id}`}>{item.fp_order_id} </p>
                                                                                </div>
                                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                                                    <p className={`${styles.e_transaction_id}`}>{item.transaction_date} </p>
                                                                                </div>
                                                                                <div className={`col-lg-2 ${styles.e_details} mb-2`}>
                                                                                    <Badge type={item.status}
                                                                                        text={item.status?.toLowerCase()}
                                                                                        className={`${styles.e_badge}`}
                                                                                        style={{ fontSize: "11px" }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.data.amount > 0 ?
                                                                <div className='row py-3'>
                                                                    <div className='col-lg-3 text-end'>
                                                                        <h6>Total</h6>
                                                                    </div>
                                                                    <div className='col-lg-2 '>
                                                                        <p className={`${styles.e_transaction_id}`}>
                                                                            ₹{props.data.amount?.toFixed(2)}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </Fragment>}
                                        </div>
                                        {props.order_status == "Partially Success" && (props.data.type == "LUMPSUM" || props.data.type == "WITHDRAW") && <div className="d-flex justify-content-end pe-3 py-3">
                                            <SecondaryBtn name="Cancel" className="e-cancel"
                                                cancel="modal" />

                                            <PrimaryBtn name="Send link to complete the transaction"
                                                className={orderIds.length === 0 ? "e-disabled" : apiLoader ? "e-btn-loader e-disabled" : ""}
                                                handleClick={_placeOrder} />

                                        </div>}
                                    </Fragment>
                                    :
                                    <EmptyNote title={
                                        props.data.failure_reason ?
                                            props.data.failure_reason
                                            :
                                            "Transaction pending"
                                    }
                                        note="Please try again later" />

                        }
                    </div>
                </div>

            </div >

        </>
    );
}
