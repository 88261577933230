/*
 *   File : addFundClass.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : add fund class name and adding fund modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import components*/
import { Input, SearchInput } from '../input/index';
import { SecondaryBtn, PrimaryBtn, PrimaryLink } from '../cta/index';
import { AggressiveFund } from '../../components/card/index';
import { Toast } from '../note';
import { Loader } from '../loader';

/*import styles*/
import fundStyles from '../../styles/components/fundClass.module.scss';
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import SearchFunds from '../../assets/images/dataFormating/search-funds.svg'
import Show from '../../assets/images/card/show.svg';
import Hide from '../../assets/images/card/hide.svg';
// import Add from '../../assets/images/modal/add.svg';
/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';


export default function FundClass(props) {

	const [name, setName] = useState("");
	const [search, setSearch] = useState("");
	const [funds, setFunds] = useState([]);
	//eslint-disable-next-line
	const [fundId, setFundId] = useState(null);
	const [selectedFunds, setSelectedFunds] = useState([]);
	const [error, setError] = useState(null);
	const [allocation, setAllocation] = useState("");
	const [response, setResponse] = useState(null);
	const [searchLoader, setSearchLoader] = useState(false);
	const [debt, setDebt] = useState(false);
	const [yearId, setYearId] = useState(null);
	const [loader, setLoader] = useState(false);
	const [success, setSuccess] = useState(null);
	const [show, setShow] = useState(false);
	const [addedFunds, setAddedFunds] = useState(false);
	const [edit, setEdit] = useState(false);

	useEffect(() => {
		var myModalEl = document.getElementById('addFundClass')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			props.close()
			setName("");
			setAllocation("");
			setSearch("");
			setFunds([]);

			setResponse(null);
			setAddedFunds(false);
			setSelectedFunds([]);
			setShow(false);
		})
		//eslint-disable-next-line
	}, [])



	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error])

	useEffect(() => {
		if (props.editedData && Object.keys(props.editedData).length > 0) {
			setName(props.editedData[0].asset_class);
			// setAllocation(props.editedData.reduce((a, b) => a + b.allocation, 0));
			setEdit(true);
		} else {
			setEdit(false);
		}
	}, [props.editedData])

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success])

	useEffect(() => {
		setDebt(!props.debt)
	}, [props.debt])

	useEffect(() => {
		if (props.goal === false) {
			setYearId(props.year.value)
		}
	}, [props.goal, props.year])

	/* ---- search funds api ---*/
	const _searchFunds = (query, first = true) => {
		setSearchLoader(first)
		let url = devconfig.admin + '/fund/search'

		let data = JSON.stringify({
			query: query ? query : search,
			page_num: 1,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setFunds(response.data);
				setResponse(response.status);
				setSearchLoader(false);
			}
			else {
				setError(response.message);
				setSearchLoader(false);
				setFunds([]);
			}
		});
	}

	/* --- get scheme code of fund --*/
	const _getData = (item) => {
		setFundId(item?.scheme_code)
	}

	/*-----save fund api---- */
	const _saveFund = (value) => {

		const requested_funds = []

		selectedFunds.map((item) => {
			return (
				requested_funds.push({
					"is_goal": props.goal,
					"is_lumpsum": props.type === 1 ? true : false,
					"is_debt": props.name !== null ? props.debt : null,
					"asset_class": name,
					"scheme_code": item.scheme_code,
					"allocation": parseFloat(item.allocation),
					"year": props.goal ? props.debt ? yearId : null : yearId,
					"category_type": props.name,
					"fund_alias": item.fund_alias,
				})
			)
		})
		setLoader(true);
		// var pattern = new RegExp(/^0*(?:[1-9][0-9][0-9]?|100)$/);
		// if (allocation >= 100) {
		let url = devconfig.admin + '/fund/add'

		let data = JSON.stringify({
			funds: requested_funds

		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Fund added successfully");
				setTimeout(() => {
					setFunds(response.data);
					$('#addFundClass').trigger('click');
					$('.btn-close').trigger('click');
					props.addedFund(true)
					setAllocation("");
					setName("");
					setFunds([]);
					setSearch("");
				}, 1000)
				setLoader(false);

			}
			else {
				setError(response.message);
				setLoader(false);
			}
		})
	}

	return (
		<>
			<div className={`${fundStyles.e_add_fund} modal fade`} id="addFundClass" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h6> Add fund </h6>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									setName("");
									setAllocation("");
									setSearch("");
									setFunds([]);

									setResponse(null);
								}}>
							</button>
						</div>
						<div className="container px-3">
							{
								!debt ?
									<div className="row">
										<div className="col-lg-12 d-flex mb-3">
											{
												props.options?.map((item) => (
													<div onClick={() => setYearId(item.value)} className={item.value === yearId ? "e-btn-active-wrapper" : "e-btn-wrapper"}>
														<span className="e-btn-active">{item.label}</span>
													</div>
												))
											}
										</div>
									</div>
									:
									null
							}
							<div className="row">
								<div className="col-lg-8">
									<label>Asset class</label>
									<Input
										placeholder="Eg: Indian equities"
										value={name}
										valueChange={(value) => setName(value)}
										type="text"
										disabled={edit}
									/>
								</div>
								<div className="col-lg-4 ps-0">
									<label>Total asset class allocation</label>
									<Input
										placeholder="100"
										value={
											allocation
										}
										disabled={true}
										// valueChange={(value) => setAllocation(value)}
										type="number"
									/>
								</div>

							</div>
							<div className='row '>
								{/* <div className='col-5 d-flex mt-3'>
									{
										name ?
											<>
												<label>Fund name : </label>
												<h6 className='ps-2 mb-0'>{name} {allocation ? ` ${allocation}%` : null}</h6>
											</>
											:
											null
									}
								</div> */}
								<div className='col-12  mt-3' style={{ cursor: "pointer" }}>
									{
										selectedFunds.length > 0 && !addedFunds ?
											!show ?
												<div className='d-flex justify-content-end' onClick={() => {
													setShow(true)
												}}>
													<p className={` mb-0 ${fundStyles.e_selected_text}`}  >Show selected funds</p>
													<img
														src={
															Show
														} alt=""
													/>
												</div>
												:
												<div className='d-flex justify-content-end' onClick={() => {
													_searchFunds(search, false);
													setShow(false)
												}}>
													<p className={` mb-0 ${fundStyles.e_selected_text}`}  >Hide selected funds</p>
													<img
														src={
															Hide
														} alt=""
													/>
												</div>
											:
											null
									}

								</div>
							</div>
							{
								!addedFunds ?
									<div className="row mt-2">
										<div className="col-lg-12 px-0">
											<div className="px-3">
												{
													!show ?
														<div className="row">
															<div className="col-lg-12 position-relative mb-4 ">
																<label>Search funds</label>
																<SearchInput placeholder="Search for funds" value={search}
																	className={fundStyles.e_search}
																	valueChange={(value) => { setSearch(value) }} onSearch={(value) => { _searchFunds(value) }} />
																{
																	search && search.length ?
																		<>
																			<span className={`${fundStyles.e_search_submit} `} onClick={() => { _searchFunds(); }}></span>
																			<button className={`${fundStyles.e_search_close} pe-3`} onClick={() => { setSearch(""); setFunds([]); setResponse(null); setResponse(null); }} ></button>
																		</>
																		:
																		null
																}

															</div>
														</div>
														:
														null
												}
												<div className="row e-funds-list position-relative">

													{
														response !== null ?
															funds?.length > 0 ?
																<>
																	<p className='mb-0'>Results</p>
																	{
																		show ?
																			selectedFunds.map((item, key) => {
																				return (
																					<>

																						<div key={key} onClick={() => { _getData(item); }}>
																							<AggressiveFund
																								selectedFunds={selectedFunds}
																								data={item}
																								getSelectedFunds={(temp) => {
																									setSelectedFunds(temp);
																								}} />
																						</div>
																					</>
																				)
																			})
																			:

																			funds.map((item, key) => {
																				return (
																					<>

																						<div key={key} onClick={() => { _getData(item); }}>
																							<AggressiveFund
																								selectedFunds={selectedFunds}
																								data={item}
																								getSelectedFunds={(temp) => {
																									setSelectedFunds(temp);

																								}} />
																						</div>
																					</>
																				)
																			})
																	}
																</>
																:
																<div className="row">
																	<h6 className="text-center"> No mutual funds found </h6>
																</div>

															:
															!searchLoader ?
																<div align="center" className='mt-5 pt-4'>
																	<img src={SearchFunds} alt="" />
																	<p >Search Funds</p>
																</div>
																:
																<Loader />
													}
												</div>
											</div>
										</div>
									</div>
									:
									<>
										<div className='row mt-2'>
											<div className='col-6'>
												Selected funds
											</div>
											<div className='col-3'>
												Fund alias
											</div>
											<div className='col-3'>
												Allocation
											</div>
										</div>
										{
											selectedFunds.map((item, key) => {
												return (
													<div className='row my-2'>
														<div className='col-6'>
															<h6>{item.scheme_name}</h6>
														</div>
														<div className='col-3'>
															<Input type="text"
																value={item.fund_alias}
																valueChange={(value) => {
																	let tempSchemes = [...selectedFunds]

																	if (value === "") {
																		tempSchemes[key].fund_alias = null;
																	} else {
																		tempSchemes[key].fund_alias = value;
																	}

																	setSelectedFunds(tempSchemes)
																}}
															/>
														</div>
														<div className='col-3 position-relative'>
															<Input type="number"
																value={item.allocation}
																suffix="%"
																valueChange={(value) => {
																	let tempSchemes = [...selectedFunds]

																	if (value === "") {
																		tempSchemes[key].allocation = "";
																	} else {
																		tempSchemes[key].allocation = parseInt(value);
																	}

																	if (tempSchemes.some((item) => (item.allocation) > 0)) {
																		const length = tempSchemes.reduce((a, b) => a + b.allocation, 0);
																		setAllocation(length)
																	} else {
																		setAllocation("")
																	}

																	setSelectedFunds(tempSchemes)
																}}
															/>
														</div>
													</div>
												)
											})
										}
									</>
							}
							<div className="row pt-4 mt-2 pb-2">
								{/* <div className='col-3 pe-0' >
									{
										addedFunds ?
											<SecondaryBtn
												name="Add more"
												className={`${styles.e_cancel} py-2 px-3`}
												img={<img src={Add} alt="" className='pe-2' />}
												handleClick={() => {
													setAddedFunds(false);
													setAllocation("");
												}}

											/>
											:
											null
									}
								</div> */}
								<div className='col-4 d-flex align-items-center justify-content-start '>
									{
										addedFunds ?

											<PrimaryLink name="Go back and add more"
												className="ps-0"
												handleClick={() => {
													setAddedFunds(false);
													setAllocation("");
												}} />

											:
											null
									}
								</div>
								<div className="col-lg-8">
									<div className="d-flex justify-content-end">
										<SecondaryBtn
											name="Cancel"
											className={`${styles.e_cancel} e-cancel`}
											cancel="modal"
											handleClick={() => {
												setName("");
												setAllocation("");
												setSearch("");
												setFunds([]);
												setResponse(null);

											}} />
										{!addedFunds &&
											name && selectedFunds.length > 0 ?
											<PrimaryBtn
												name="Add funds"
												className={loader ? " e-btn-loader" : ""}
												handleClick={() => {
													setAddedFunds(true);

												}} />
											:
											!addedFunds &&
											<PrimaryBtn
												name="Add funds"
												className="e-disabled " />
										}
										{
											addedFunds && selectedFunds.filter((item) => item.allocation !== '').length === selectedFunds.length && selectedFunds.filter((item) => item.fund_alias !== null).length === selectedFunds.length ?
												<PrimaryBtn
													name="Add funds"
													className={loader ? " e-btn-loader" : ""}
													handleClick={() => {

														_saveFund()

													}} />
												:
												addedFunds &&
												<PrimaryBtn
													name="Add funds"
													className="e-disabled " />
										}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Error toast */}
				{
					error !== null ?
						<Toast data={error} type="error" id="1" />
						:
						null
				}

				{/* success toast */}
				{
					success !== null ?
						<Toast data={success} type="success" id="2" />
						:
						null
				}
			</div>
		</>
	)
}
