/*
 *   File : detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 27-04-2022
 *   Version : v1.0
 *   Created : 10-12-2021
 */
/*import pakcages*/
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

/*import components*/
import { Tab, } from '../../components/dataFormating/index';
import { UserDetail } from '../../components/card/index';
import { ConfirmationforBlock, TransactionDetail, UpdateBankDetail } from '../../components/modal/index';
import { Loader } from '../../components/loader';
import { EmptyNote, Toast } from '../../components/note';
import { AccordionUserInvestment } from "../../components/dataFormating";


/*import container*/
import Profile from './profile.js';
import Holdings from './holdings.js';
import Orders from './orders';
import SipList from './sip-list';
import MandateList from './mandates';
import PendingGoal from './pending-goal';

/*import assets*/
import Next from '../../assets/images/navigation/next.svg';
import Previous from '../../assets/images/navigation/previous.svg';

/*import services*/
import GetVars from '../../services/getUrl';
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import ExternalTransactions from './external-transactions.js';


const UserDetails = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tabIndex, setTabindex] = useState(1);
    const [id, setId] = useState(null);
    const [detail, setDetail] = useState({});
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(null);
    const [type, setType] = useState(null);
    const [summaryLoader, setSummaryLoader] = useState(false);
    const [goalSummaryData, setGoalSummaryData] = useState([]);
    const [tab, setTab] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [search, setSearch] = useState('');
    const [isInvested, setIsInvested] = useState(null);


    useEffect(() => {
        GetVars().then((query) => {
            if (query && query.tab) {
                setTabindex(Number(query.tab));

            }
            if (query && query.id) {
                setId(query.id);
                if (location.state && location) {
                    if (location.state.tab) {
                        setTabindex(location.state.tab);
                        setTab(true);
                        getDetail(query.id, startDate, endDate, isInvested, search);
                    } else {
                        getDetail(query.id,
                            location.state.from_date,
                            location.state.to_date,
                            location.state.is_invested,
                            location.state.query);
                        setStartDate(location.state.from_date);
                        setEndDate(location.state.to_date);
                        setSearch(location.state.query);
                        setIsInvested(location.state.is_invested)
                    }

                } else {
                    getDetail(query.id, null, null, null, '')
                }

                getGoalSummary(query.id);
                // getRiskData(query.id);
                // getScoreRequest(query.id)
            }
            if (query && query.type) {
                setType(query.type);
            }
        });

        //eslint-disable-next-line
    }, [window.location.href]);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    /* ----- get user's deatil api -----*/

    const getDetail = (temp, start_date, end_date, is_invested, search_query) => {
        setLoader(true);
        let url = devconfig.admin + '/user/detail';
        let data = JSON.stringify({
            "user_id": parseInt(temp),
            "from_date": start_date,
            "to_date": end_date,
            "is_invested": is_invested,
            "query": search_query
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setDetail(response.data)
                setLoader(false)
            }
            else {
                setLoader(false);
                setDetail({})
            }
        })
    }

    /*----- get goal summary ----*/

    const getGoalSummary = (temp) => {
        setSummaryLoader(true);

        let url = devconfig.admin + '/user/goal-summary';
        let data = JSON.stringify({
            "customer_id": parseInt(temp)
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSummaryLoader(false);
                setGoalSummaryData(response.data);
            } else {
                setSummaryLoader(false);
                setGoalSummaryData([]);
            }
        });

    }

    const AllocationData = [
        {
            allocation_id: 1,

        },
        {
            allocation_id: 2,

        }
    ]
    return (
        <>
            {
                loader ?
                    <div className='col-lg-12 '>
                        <Loader />
                    </div>

                    :
                    Object.values(detail).length > 0 ?
                        <>
                            <div className="row">
                                <div className="col-lg-12 pt-1 pb-1">
                                    {
                                        type === 2 ?
                                            <span className="e-breadcrumb-link"><Link to="/analytics ">Analytics</Link>/{detail?.overview?.name}</span>
                                            :
                                            <span className="e-breadcrumb-link"><span><Link to="/users ">Users</Link></span>/{detail?.overview?.name}</span>
                                    }

                                </div>
                            </div>
                            <div className="row">

                                {/*-----------card displaying basic details------------------*/}
                                <div className="col-lg-12">
                                    <UserDetail
                                        data={detail}
                                        userId={id}
                                    />
                                </div>

                                {/*----------- tabs ------------------*/}
                                <div className="col-lg-12 mt-3 mb-3 d-flex justify-content-between">
                                    <Tab
                                        data={['Profile & KYC', 'Orders', 'Holdings', 'Investment with goals', "SIPs", "Mandates", "Pending goals", "External Transactions"]}
                                        setTab={(temp) => {
                                            setTabindex(temp);
                                            if (tab) {
                                                navigate("/user-detail?id=" + id)
                                            }
                                        }}
                                        currentIndex={tabIndex}
                                    />
                                    <div className='d-flex pe-4'>
                                        {
                                            detail.prev_customer_id !== null ?
                                                <img src={Next} alt="next"
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                        getDetail(detail.prev_customer_id, startDate, endDate, isInvested, search);
                                                        navigate("/user-detail?id=" + detail.prev_customer_id, {
                                                            state: {
                                                                from_date: startDate,
                                                                to_date: endDate,
                                                                is_invested: isInvested,
                                                                query: search,
                                                            }
                                                        })
                                                    }}
                                                />
                                                :
                                                <img src={Next} alt="next"
                                                    className='ps-4 cursor-pointer' style={{ opacity: "0.2", pointerEvents: "none" }} />
                                        }
                                        {
                                            detail.next_customer_id !== null ?
                                                <img src={Previous} alt="next" className='ps-4 cursor-pointer'
                                                    onClick={() => {
                                                        getDetail(detail.next_customer_id, startDate, endDate, isInvested, search);
                                                        navigate("/user-detail?id=" + detail.next_customer_id, {
                                                            state: {
                                                                from_date: startDate,
                                                                to_date: endDate,
                                                                is_invested: isInvested,
                                                                query: search
                                                            }
                                                        })
                                                    }} />
                                                :
                                                <img src={Previous} alt="next"
                                                    className='ps-4 cursor-pointer' style={{ opacity: "0.2", pointerEvents: "none" }} />
                                        }
                                    </div>
                                </div>
                                {
                                    tabIndex === 1 ?
                                        <Profile
                                            data={detail}
                                            userId={id}
                                            editUserDetails={() => getDetail()}
                                        />
                                        : tabIndex === 2 ?
                                            <Orders
                                                id={id}
                                                fName={detail.overview && detail.overview.name ? detail.overview.name : "--"}
                                            // lName={detail.personal_data.last_name}
                                            />
                                            :
                                            tabIndex === 3 ?
                                                <Holdings
                                                    id={id}
                                                />
                                                :
                                                tabIndex === 4 ?
                                                    <div className="row pe-0">
                                                        <div className="col-lg-12 pe-0">
                                                            <div className="card e-dashboard-card border-0 p-4 position-relative">
                                                                {
                                                                    summaryLoader ?
                                                                        <Loader />
                                                                        :
                                                                        goalSummaryData.length > 0 ?
                                                                            <AccordionUserInvestment
                                                                                data={AllocationData}
                                                                                summaryData={goalSummaryData} />
                                                                            :
                                                                            <EmptyNote note="No data found" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    tabIndex === 5 ?
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <SipList
                                                                    id={id} />
                                                            </div>
                                                        </div>
                                                        :
                                                        tabIndex === 6 ?
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <MandateList
                                                                        id={id} />
                                                                </div>
                                                            </div>
                                                            :
                                                            tabIndex === 7 ?
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <PendingGoal
                                                                            id={id} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                tabIndex === 8 ?
                                                                    <ExternalTransactions
                                                                        id={id}
                                                                        fName={detail.overview && detail.overview.name ? detail.overview.name : "--"}
                                                                    // lName={detail.personal_data.last_name}
                                                                    />
                                                                    :
                                                                    <Profile
                                                                        data={detail}
                                                                        userId={id}
                                                                        editUserDetails={() => getDetail()}
                                                                    />
                                }

                            </div>
                            <TransactionDetail />

                            {/* Toast error */}
                            {
                                error !== null ?
                                    <Toast data={error}
                                        type="error"
                                        id="1" />
                                    :
                                    null
                            }
                        </>
                        :
                        <div className='e-dashboard-card card pb-5 mb-5'>
                            <EmptyNote note="Customer details not found" />
                        </div>
            }
            {/* confirmation modal for blocking */}
            {!loader && <ConfirmationforBlock
                // content="Are you sure , you want to block this user?"
                title={`Are you sure you want to ${detail.overview.is_blocked ? "Unblock" : "Block"} this user?`}
                id={id}
                buttonName={detail.overview.is_blocked ? "Unblock" : "Block"}
                updateStatus={() => {
                    getDetail(id, startDate, endDate, isInvested, search)
                }} />}

            <UpdateBankDetail
                data={detail}
                id={id}
                update={() => {
                    getDetail(id, startDate, endDate, isInvested, search)
                }} />
        </>
    );
}

export default UserDetails;
