/*
 *   File : orderDetail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order detail Modal
 *   Integrations : 24-05-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect, Fragment } from 'react';
import $ from 'jquery'
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';

/*import componets*/
import { Badge } from '../../components/badge';
import { Input } from '../../components/input';
import { EmptyNote, Toast } from '../../components/note';
import Tooltip from '../../components/badge/tooltip';
import { Loader } from '../../components/loader';

import { PrimaryBtn, SecondaryBtn } from '../../components/cta';
/*import styles*/
import styles from '../../styles/components/transactionDetail.module.scss';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';



export default function OrderDetail(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [orderData, setOrderData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [show, setShow] = useState(null);
    const [orderIds, setOrderIds] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            orderDetail()
        }
        //eslint-disable-next-line
    }, []);


    function _handleOrderIds(id) {
        const order_ids = [...orderIds];
        const order_index = order_ids.findIndex((item) => item === id);
        if (order_index < 0) {
            order_ids.push(id);
        } else {
            order_ids.splice(order_index, 1);
        }

        setOrderIds(order_ids)
    }

    const orderDetail = () => {
        setLoader(true)
        let url = ""
        let data = {}
        if (location.state.user_id) {
            url = devconfig.admin + "/order/detail";
        } else {
            url = devconfig.admin + "/order/detail";
        }

        if (location.state.user_id) {
            data = JSON.stringify({
                user_id: Number(location.state.user_id),
                order_id: location.state.data.order_id
            })
        } else {
            data = JSON.stringify({
                order_id: location.state.data.order_id
            })
        }

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setOrderData(response.data);
            } else {
                setOrderData([]);
            }
            setLoader(false)
        })
    }

    // API -place order 
    const _placeOrder = () => {
        setApiLoader(true);

        const url = devconfig.admin + "/order/place-order";
        const data = JSON.stringify({
            order_ids: orderIds
        })

        APIPost(url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Order placed successfully", {
                    type: "success"
                })
                setApiLoader(false);
                setOrderIds([]);
            } else {
                toast.error(response.message, {
                    type: "error"
                })
                setApiLoader(false)
            }
        })
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0 py-1">
                        <div className="col-lg-12 pt-2">
                            <h6 className="e-section-title mb-0">
                                <span className="e-breadcrumb-link"><span><Link to="/orders ">Orders</Link></span>/Order detail</span>
                                {/* <TextBtn name="View Summary"
										className="ms-2"
										handleClick={() => _handleShowSummary(true)} /> */}
                            </h6>
                        </div>
                        <div className={`${styles.e_transaction_detail}`}>
                            <div className={`${styles.e_dialog}`}>
                                <div className={`${styles.e_modal_content}`}>

                                    {
                                        loader ?
                                            <Loader />
                                            :

                                            orderData.length > 0 ?
                                                <Fragment>
                                                    <div className={`${styles.e_transaction_content}`}>
                                                        <div className="row p-4 ps-3">
                                                            <div className="col-lg-4 col-6 pe-0">
                                                                <div className='row'>
                                                                    <div className='col-lg-1 col-2 px-0 d-flex align-items-center justify-content-start'>
                                                                        <div className={`${styles.e_icon} d-flex align-items-center justify-content-center`}>
                                                                            {location.state.data.name ? location.state.data.name?.charAt(0) : location.state.fName?.charAt(0)}
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-9'>
                                                                        <div className={`${styles.e_span} ms-3 mt-2 `}>
                                                                            {
                                                                                location.state.fName ?
                                                                                    <h6 className='mb-1'>{location.state.fName}</h6>
                                                                                    :
                                                                                    <h6 className='mb-1 ' onClick={() => {
                                                                                        navigate(`/user-detail?id=${location.state.customer_id}`)
                                                                                    }}>
                                                                                        <span className='e-link'>{location.state.data.name}</span>

                                                                                    </h6>
                                                                            }
                                                                            <p className={`${styles.e_time_date}  mb-0`}>{location.state.user_id ? location.state.data.created : `${location.state.data.order_time}`}</p>
                                                                            <p className={`${styles.e_time_date}  mb-0`}>{location.state.user_id ? location.state.data.mira_order_id : location.state.order_no}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2 col-4 text-start px-0 position-relative mt-2">

                                                                <h6 className='mb-0 pt-0 '>

                                                                    Payment status
                                                                    <span onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}
                                                                        className={
                                                                            location.state.data.payment_status === "Failed" ? `e-icon` :
                                                                                location.state.data.payment_status === "Processing" ? `e-icon` :
                                                                                    null
                                                                        }></span>
                                                                    {
                                                                        show ?
                                                                            location.state.user_id ?
                                                                                <Tooltip
                                                                                    className={styles.e_tooltip}
                                                                                    content={
                                                                                        location.state.data.payment_failed_reason ?
                                                                                            location.state.data.payment_failed_reason :
                                                                                            location.state.data.payment_status === "Processing" ?
                                                                                                "Transaction pending"
                                                                                                :
                                                                                                "Transaction failed"
                                                                                    } />
                                                                                :
                                                                                <Tooltip
                                                                                    className={styles.e_tooltip}
                                                                                    content={
                                                                                        location.state.data.failure_reason ?
                                                                                            location.state.data.failure_reason :
                                                                                            location.state.data.payment_status === "Processing" ?
                                                                                                "Transaction pending"
                                                                                                :
                                                                                                "Transaction failed"
                                                                                    } />

                                                                            :
                                                                            null
                                                                    }
                                                                </h6>
                                                                {
                                                                    location.state.data.payment_status !== null ?
                                                                        <Badge type={location.state.data.payment_status}
                                                                            text={location.state.data.payment_status.toLowerCase()}
                                                                            className={`${styles.e_badge}`}
                                                                            style={{ fontSize: "11px" }} />
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-lg-2 col-6 text-start px-0 mt-2">
                                                                <h6 className='mb-0 pt-0 '>Order status</h6>
                                                                {
                                                                    location.state.data.order_status !== null ?
                                                                        <Badge type={location.state.data.order_status}
                                                                            text={location.state.data.order_status.toLowerCase()}
                                                                            className={`${styles.e_badge}`}
                                                                            style={{ fontSize: "11px" }} />
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-lg-2 col-4 text-start mt-2">
                                                                <h6 className='mb-0 pb-1 pt-0 '>Type</h6>
                                                                <p>{location.state.data.type}</p>
                                                            </div>
                                                            <div className="col-lg-2 col-4 text-start mt-2">
                                                                <h6 className='mb-0 pb-1 pt-0 '>Related Order</h6>
                                                                <p onClick={() => navigate(-1)} className='e-link'>{location.state.data.order_id}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='e-order-scroll'>
                                                        <div className="e-table-scroll">
                                                            {
                                                                location.state.data.type === "SWITCH" || location.state.data.type === "Switch" ?
                                                                    <Fragment>
                                                                        <div className='container-fluid ps-3'>
                                                                            <div className='row mt-4 pe-3 ps-1'>
                                                                                <div className={`col-4 ${styles.e_details} mb-2`}>
                                                                                    <h6>Transaction ID:</h6>
                                                                                </div>
                                                                                <div className={`col-3 ${styles.e_details} mb-2`}>
                                                                                    <h6>Amount</h6>
                                                                                </div>
                                                                                <div className={`col-3 ${styles.e_details} mb-2`}>
                                                                                    <h6>Date</h6>
                                                                                </div>
                                                                                <div className={`col-2 ${styles.e_details} mb-2 `}>
                                                                                    <h6>Status</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            orderData.map((item, key) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className={`container-fluid px-3`}>
                                                                                            <div className={orderData.length === key + 1 ?
                                                                                                key % 2 !== 0 ? `border-bottom row pt-3 mb-3  pe-4 ps-1 ` : `border-bottom e-order-row row pt-3 mb-3  pe-4 ps-1`
                                                                                                :
                                                                                                key % 2 !== 0 ? `row pt-3 mb-3  pe-4 ps-1` : `e-order-row row pt-3 mb-3  pe-4 ps-1`}>
                                                                                                <div className={`col-4 ${styles.e_details} mb-3`}>
                                                                                                    <div className='d-flex align-items-center'>
                                                                                                        <p className={`${styles.e_transaction_id} ms-2 mb-2`}>{item.transaction_id} </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className={`col-3 ${styles.e_details} mb-3 px-0`}>
                                                                                                    <p className={`${styles.e_transaction_id} mb-0`}>₹{parseFloat(item.amount?.split("₹")[1])?.toFixed(2)}</p>
                                                                                                </div>
                                                                                                <div className={`col-3 ${styles.e_details} mb-3`}>
                                                                                                    <p className={`${styles.e_transaction_id} mb-0`}>{item.transaction_date} </p>
                                                                                                </div>
                                                                                                <div className={`col-2 ${styles.e_details} mb-2`}>

                                                                                                    <Badge type={item.status}
                                                                                                        text={item.status?.toLowerCase()}
                                                                                                        className={`${styles.e_badge}`}
                                                                                                        style={{ fontSize: "11px" }} />
                                                                                                </div>


                                                                                                <h6 className={`${styles.e_details}`}>Switch Fund & Units:</h6>
                                                                                                <div className={`col-5 ${styles.e_details}`}>
                                                                                                    <p className={`${styles.e_transaction_id}`}>
                                                                                                        {item.switch_in_fund_name}
                                                                                                        <br />
                                                                                                        {item.switch_in_units}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='col-2 text-center'>
                                                                                                    <img src={require("../../assets/images/cta/arrow-blue.svg").default}
                                                                                                        width={"24px"} />
                                                                                                </div>
                                                                                                <div className={`col-5 ${styles.e_details}`}>
                                                                                                    <p className={`${styles.e_transaction_id}`}>
                                                                                                        {item.switch_out_fund_name}
                                                                                                        <br />
                                                                                                        {item.switch_out_units}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div >
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            location.state.data.amount > 0 ?

                                                                                <div className={`${styles.e_transaction_id} d-flex justify-content-end align-items-center py-3`}>
                                                                                    <h6 className='mb-0'>Total</h6>  ₹{location.state.data.amount?.toFixed(2)}
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }

                                                                    </Fragment>
                                                                    :
                                                                    <Fragment>
                                                                        <div className='container-fluid ps-3'>
                                                                            <div className='row mt-4 pe-3 ps-1'>
                                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                                    <h6>Transaction ID:</h6>
                                                                                </div>
                                                                                <div className={`col-2 ${styles.e_details} mb-2 px-0`}>
                                                                                    <h6>Amount & units</h6>
                                                                                </div>
                                                                                <div className={`col-2 ${styles.e_details} mb-2 px-0 `}>
                                                                                    <h6 className='ps-4'>Invested in</h6>
                                                                                </div>
                                                                                <div className={`col-2 ${styles.e_details} mb-2 px-0 text-center`}>
                                                                                    <h6>FP Order ID</h6>
                                                                                </div>
                                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                                    <h6>Date</h6>
                                                                                </div>
                                                                                <div className={`col-2 ${styles.e_details} mb-2 `}>
                                                                                    <h6>Status</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            orderData.map((item, key) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className={`container-fluid px-3`}>
                                                                                            <div className={orderData.length === key + 1 ?
                                                                                                key % 2 !== 0 ? `border-bottom row pt-3 mt-1  pe-4 ps-1` : `border-bottom e-order-row row pt-3 mt-1  pe-4 ps-1`
                                                                                                :
                                                                                                key % 2 !== 0 ? `row pt-3 mt-1  pe-4 ps-1` : `e-order-row row pt-3 mt-1  pe-4 ps-1`}>
                                                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                                                    <div className='d-flex align-items-center position-relative'>
                                                                                                        {
                                                                                                            (
                                                                                                                location.state.data.order_status == "Partially Success" || 
                                                                                                                location.state.data.order_status == "Failed"
                                                                                                            ) && 
                                                                                                            (
                                                                                                                location.state.data.type == "LUMPSUM" || 
                                                                                                                location.state.data.type == "WITHDRAW" || 
                                                                                                                location.state.data.type === "SIP"
                                                                                                            ) && 
                                                                                                            (
                                                                                                                item.status === "Failed" || item.status==="Reversed"  
                                                                                                            ) &&
                                                                                                            <span onMouseEnter={() => item.is_order_placed === true && setShowTooltip(item.id)} onMouseLeave={() => setShowTooltip(false)}>
                                                                                                                <Input type="checkbox"
                                                                                                                    id="e-select"
                                                                                                                    disabled={item.is_order_placed}
                                                                                                                    className="cursor-pointer"
                                                                                                                    checked={orderIds.includes(item.id)}
                                                                                                                    valueChange={() => _handleOrderIds(item.id)} />
                                                                                                            </span>}
                                                                                                        <p className={`${styles.e_transaction_id} ms-2 mb-2`}>{item.transaction_id} </p>
                                                                                                        {
                                                                                                            showTooltip === item.id &&
                                                                                                            <Tooltip
                                                                                                                className={`start-0 ${styles.e_tooltip} `}
                                                                                                                content={
                                                                                                                    "Order already placed"
                                                                                                                } />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className={`col-2 px-0 ${styles.e_details} mb-2`}>
                                                                                                    <p className={`${styles.e_transaction_id} mb-0 pb-0`}>₹{item.amount ? parseFloat(item.amount.split("₹")[1])?.toFixed(2) : 0} </p>
                                                                                                    <p className={`${styles.e_transaction_id} mt-0 mb-0 pt-0`}>Units :{item.units} </p>
                                                                                                    <p className={`${styles.e_transaction_id} mb-0 pt-0`}>Nav: {item.order_response ? item.order_response.purchased_price : "-"}</p>
                                                                                                    <p className={`${styles.e_transaction_id} mb-0 pt-0`}>Nav date: {item.order_response ? item.order_response.traded_on : "-"}</p>
                                                                                                </div>
                                                                                                <div className={`col-2 ${styles.e_details} mb-2 px-0`}>
                                                                                                    <p className={`${styles.e_transaction_id} ps-4`}>{item.mutual_fund} </p>
                                                                                                </div>
                                                                                                <div className={`col-2 ${styles.e_details} mb-2 px-0 text-center`}>
                                                                                                    <p className={`${styles.e_transaction_id}`}>{item.fp_order_id} </p>
                                                                                                </div>
                                                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                                                    <p className={`${styles.e_transaction_id}`}>{item.transaction_date} </p>
                                                                                                </div>
                                                                                                <div className={`col-2 ${styles.e_details} mb-2`}>
                                                                                                    <Badge type={item.status}
                                                                                                        text={item.status?.toLowerCase()}
                                                                                                        className={`${styles.e_badge}`}
                                                                                                        style={{ fontSize: "11px" }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            location.state.data.amount > 0 ?
                                                                                <div className={`${styles.e_transaction_id} d-flex justify-content-end align-items-center py-3`}>
                                                                                    <h6 className='mb-0'>Total</h6>  ₹{location.state.data.amount?.toFixed(2)}
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }

                                                                    </Fragment>}
                                                            {(location.state.data.order_status == "Partially Success" || location.state.data.order_status == "Failed") && (location.state.data.type === "SIP" || location.state.data.type == "LUMPSUM" || location.state.data.type == "WITHDRAW") && <div className="d-flex justify-content-end pe-3 py-3">
                                                                <SecondaryBtn name="Cancel" className="e-cancel"
                                                                    handleClick={() => navigate(-1)} />

                                                                <PrimaryBtn name="Send link to complete the transaction"
                                                                    className={orderIds.length === 0 ? "e-disabled" : apiLoader ? "e-btn-loader e-disabled" : ""}
                                                                    handleClick={_placeOrder} />

                                                            </div>}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                :
                                                <EmptyNote title={
                                                    location.state.data.failure_reason ?
                                                        location.state.data.failure_reason
                                                        :
                                                        "Transaction pending"
                                                }
                                                    note="Please try again later" />

                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
            <Toast />
        </>
    );
}
