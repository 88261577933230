/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React from 'react';
import DataTable from 'react-data-table-component';

export default function Datatable(props) {
	return (
		<>
			<DataTable
				columns={props.columns}
				data={props.data}
				paginationPerPage={props.perPage ? props.perPage : 10}
				pagination={props.pagination}
				onRowClicked={(row) => props.rowClick ? props.rowClick(row) : null}
				selectableRows={props.selectableRows}
				clearSelectedRows={props.clearSelectedRows}
				paginationServer={props.paginationServer}
				paginationDefaultPage={props.paginationDefaultPage}
				paginationTotalRows={props.paginationTotalRows}
				onChangePage={props.onChangePage}
				onSelectedRowsChange={(row) => props.selectRows(row)}
				onChangeRowsPerPage={props.onChangeRowsPerPage}
			/>
		</>
	);
}




