/*
 *   File : user-summary.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : User summary Modal
 *   Version : v1.0
 */


/*import styles*/
import { useEffect } from 'react';
import styles from '../../styles/components/transactionDetail.module.scss';


const SummaryModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("summary");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);
    return (
        <div className={`${styles.e_transaction_detail} modal fade`} id="summary" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className="modal-header px-2 pt-2">
                        <h6 className={styles.e_modal_title}>{props.title} Summary</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {
                        props.type === 1 ?
                            <div className='row'>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Address added </h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.address_added_users} ({props.data.address_added_percentage}%)</span>
                                    </div>
                                </div>

                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Bank added</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.bank_added_users} ({props.data.bank_added_percentage}%)</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>First time login</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.first_time_login} ({props.data.first_time_login_percent}%)</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>KYC done through MIRA</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.kyc_done_through_mira}</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Total invested users</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.no_of_invested} ({props.data.invested_percentage}%)</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Nominee added</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.nominee_added_users} ({props.data.nominee_added_percentage}%)</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>PAN added</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.pan_added_users} ({props.data.pan_added_percentage}%)</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Ready to Invest</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.ready_to_invest} ({props.data.ready_to_invest_percentage}%)</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Returning users</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.returning_users} ({props.data.returning_users_percentage}%)</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Total users</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.total_users}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='row'>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Total Investment</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.total_investment}</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Total Lumpsum</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.total_lumpsum}</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Total Redemption</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.total_redemption}</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Total SIP</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.total_sip}</span>
                                    </div>
                                </div>
                                <div className='col-4 pb-4'>
                                    <div className={`${styles.e_span} ms-3 pt-1`}>
                                        <h6>Total Value</h6>
                                        <span className={`${styles.e_time_date} pt-1 mb-0`}>{props.data.total_value}</span>
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </div>
        </div>

    )
}

export default SummaryModal;